export const TRANSLATIONS_IT = {
    welcome:"Benvenuti",
    indietro :'indietro',
    preventivo :"preventivo",
    preventivi :"preventivi",
    modello :"modello",
    modelli :"modelli",
    opzione:'opzione',
    opzioni:'opzioni',
    utente:"utente",
    utenti:"utenti",
    configuratore:"configuratore",
    nuovo:"nuovo",
    nuovo_preventivo:"nuovo preventivo",
    aggiungi_opzione:"Nuova voce/Note",
    modifica: "modifica",
    cancella:"cancella",
    salva:"salva",
    annulla:"annulla",
    isRequired:"è obbligatorio",
    excludes: "escluso da",
    requires:"richiesto da",
    require_t:"richiede",
    excludes_t: "esclude",
    isRequired_t: "è obbligatorio",
    confermaModifica: "Conferma modifica",
    confermaModificaErrore: "Selezionare almeno un'opzione",
    invalidEmail:"non è un indirizzo email valido",
    configurationErrorMessageTitle:"Nota bene",
    customer: "cliente",
    crmCode:"codice CRM",
    email:"email",
    address:"indirizzo",
    phone:"telefono",
    phoneAreaCode: "prefisso",
    city: "città",
    zipCode: "cap",
    country: "nazione",
    customerFirstName:"nome cliente",
    customerLastName:"cognome cliente",
    customerEmail:"email cliente ",
    customerAddress:"indirizzo cliente",
    customerPhone:"telefono cliente",
    customerOwnedBoat:"barca di proprietà",
    customerNavigationArea:"area di navigazione ",
    notes:"note preventivo",
    titoloPreventivo:"titolo preventivo",
    discount:"sconto",
    freightCharges:"costi spedizione",
    tradeInValue:"valore permuta",
    tradeInDescription:"descrizione permuta",
    validity:"validità",
    delivery:"consegna",
    paymentTerms:"pagamento",
    condizioniCommerciali:"Condizioni commerciali addizionali per formulazione proposta d'acquisto",
    condizioniVendita:"Condizioni di vendita",
    prezzoTotale:"Prezzo totale IVA esclusa",
    prezzoNetto:"Prezzo netto  IVA esclusa",
    cerca: "cerca",
    searchQuery: "testo ricerca",
    searchStartDate : "dal",
    searchEndDate : "al",
    nomeUtente: "nome utente",
    customData: "testo personalizzato per pdf",
    userFormPasswordHelpText: "lasciare vuoto per non modificare",
    data:"data",
    luogo:"luogo",
    firma:"firma",
    dealer: "dealer",
    ragioneSociale:"ragione sociale",
    crmUserId:"CRM User Id",
    crmContactSearch:"email cliente",
    crmSearchButton: "cerca",
    crmContactOwnerState_conflict: 'contact owner doesn\'t match',
    crmContactOwnerState_match: 'contact owner match',
    crmContactOwnerState_unset: 'contact owner unset',

};