//https://stackoverflow.com/questions/34321128/render-array-of-inputs-in-react

import React, { Component } from 'react'
import api, {findCrmContactsAll} from '../api'
import styled from 'styled-components'
import {Tabs, Tab, Modal, Card, ListGroup} from 'react-bootstrap'
import OpzioneComponent from '../components/Opzione'
import treeOpzioni from '../utilities/treeOpzioni'
import CommonComponents from '../components/CommonComponents'
import '../style/optionComponent.css'

const queryString = require('query-string');



const Title = styled.h1.attrs({
    className: 'h1',
})``
/*
const Wrapper = styled.div.attrs({
    className: 'form-group',
})`
    margin: 0 30px;
`
*/
const Label = styled.label`
    margin: 5px;
`

const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin: 5px;
`

const Button = styled.button.attrs({
    className: `btn btn-primary`,
})`
    margin: 15px 15px 15px 5px;
`

const ButtonPrimaryOutline = styled.button.attrs({
    className: `btn btn-outline-primary`,
})`
    margin: 15px 15px 15px 5px;
`

const ButtonSecondary = styled.button.attrs({
    className: `btn btn-secondary btn-sm`,
})``

const Checkbox = styled.input.attrs({
    className: 'form-check-input',
})``

function getTextInLangArr(arr, langKey) {
    //console.log( arr, langKey);

    let result = '';
    if(!arr || !langKey) return result;

    for(let i = 0; i < arr.length; i++){
        if(arr[i].lang === langKey) result = arr[i].text;
    }
    return result;
}

/**
 * se il nome del campo termina con -CODICE_LINGUA (esempio "nome-IT")
 * return {"lang" : codice lingua , "fieldName": nome del campo senza codice lingua}
 * @param languageArr
 * @param fieldName
 * @returns {*}
 */
function getLangFieldMap(languageArr, fieldName) {
    let isLangField = false;

    for(let k = 0; k < languageArr.length; k++){
        if(fieldName.endsWith('-' + languageArr[k])) {
            isLangField = true;
        }
    }

    if(!isLangField) return isLangField;

    let arr = fieldName.split('-')
    let fname = arr[0]
    let langKey = arr[1]
    //console.log(fname, langKey)

    return {"lang" : langKey, "fieldName": fname}

}

/**
 * aggiorna l'array dei campi language
 * @param arr
 * @param valueMap {"lang" : codice lingua, "fieldName": nome del campo, "text": String}
 * @returns {*}
 */
function updateValueInArr(arr, valueMap) {
    if(!arr) arr = [];
    let isInArray = false;

    for(let k = 0; k < arr.length; k++){

        //console.log(arr[k])

        if(arr[k].lang === valueMap.lang) {
            arr[k].text = valueMap.text
            isInArray = true
        }
    }

    if(!isInArray){
        arr.push({"lang" : valueMap.lang, "text": valueMap.text})
    }

    return arr;
}

class LanguageInputText extends Component {
    constructor(props) {
        super(props)
        this.myName = this.props.name + '-' + this.props.languageKey;
    }

    render() {
        return (
            <div>
                <Label>{this.props.name}</Label>
                <input type="text" className="form-control" name={this.myName} value={this.props.value} onChange={this.props.onChange}  />
            </div>
        );
    }
}


class ModelSelect extends Component {


    render() {
        return (
            <select className="form-control" value={this.props.value} name={this.props.name} onChange={this.props.onChange}>
                <option  value="">selezionare</option>
                {this.props.models.map((item, index)=> {
                    return(<option key={index} value={item.key}>{item.name}</option>
                    )
                })}

            </select>

        );
    }
}

class EditRequire extends Component {

    render() {
        //console.log(this.props.element)
        let html = <></>,
        fieldName = "edit-require-" + this.props.element._id,
        value = this.props.element._id;

        let isInIndex = this.props.selectedValues.findIndex(function(o){
            return o === value;
        })


        if (this.props.element.isSelectable) {
            html =   <div className="form-check">
                <input className="form-check-input" type="checkbox" value={this.props.element._id} id={fieldName} name={fieldName} checked={isInIndex > -1} onChange={this.props.onChange} />
                <label className="form-check-label" htmlFor={fieldName}>
                    {this.props.element.nameArr[0].text}
                </label>
            </div>
        }

        //console.log(this.props.element)
        return (<>
            {html}
        </>
        );
    }
}

class RequiredOpzione extends Component {

    render() {

        return(<>
        {this.props.items.map((item, index)=>{

            let label = item,
                opzione = treeOpzioni.lookupOptionById(item, this.props.modelOptions)

            if(opzione) label = opzione.nameArr[0].text

            return(<ListGroup.Item key={index}>{label}</ListGroup.Item>)

        })}
        </>)

    }
}



//console.log('window.languageArr', window.languageArr)
class OpzioneUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            modelKey: '',
            gruppo: '',
            rank: '',
            tipo: 'opzione',
            isRequired: false,
            isSelectable: true,
            isMultiple: false,
            isStandard: false,
            useAccordion: false,
            isFixed: false,
            price:0,
            languageArr : window.languageArr,
            models:[],
            modelOptions:[],
            modelOpzioniTree : [],
            requires: [],
            excludes: [],
            isOpen: false,
            modalEditTarget : '',
            editRequireArray: []
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEditRequireChange = this.handleEditRequireChange.bind(this);

    }

    deleteRequire = (indexInRequiredArray) => {

        const newRequires = [...this.state.requires]
        //console.log('this.state.requires',this.state.requires)
        newRequires.splice(indexInRequiredArray, 1);
        //console.log('newRequires',newRequires)
        this.setState({ requires: newRequires })
    }

    addRequire = (indexInRequiredArray) => {

        if(this.state.editRequireArray.length < 1) return

        const newRequires = [...this.state.requires]
        //console.log('this.state.requires',this.state.requires)
        newRequires.push(this.state.editRequireArray);
        console.log('newRequires',newRequires)
        this.setState({ requires: newRequires, editRequireArray: [] })
    }


    handleEditRequireChange(event) {
        const target = event.target;
        let value = target.value,
        newEditRequireArray =[...this.state.editRequireArray];

        if(target.checked) {
            // checked

            newEditRequireArray.push(value)
        }else{
            // non checked

            let index = newEditRequireArray.findIndex(function(o){
                return o === value;
            })
            if (index !== -1) newEditRequireArray.splice(index, 1);
        }

        console.log('newEditRequireArray',newEditRequireArray)
        this.setState({editRequireArray: newEditRequireArray})

    }

    openModal = (modalEditTarget) => {
        //console.log('modalEditTarget',modalEditTarget)
        this.setState({ modalEditTarget: modalEditTarget, isOpen: true });}

    closeModal = () => this.setState({ isOpen: false });

    handleInputChange(event) {
        //console.log("handleInputChange")
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        console.log(name, value)
        console.log('this.state.modelKey', this.state.modelKey)


        // input-arr
        if(target.className && target.className.indexOf('input-arr')> -1){
            value = value.split(',')
        }

        let langFieldMap = getLangFieldMap(this.state.languageArr, name)
        //console.log('getLangFieldName', langFieldMap)

        // gestione campi multilingua
        if(langFieldMap){
            langFieldMap.text = value;
            name = langFieldMap.fieldName + 'Arr';
            value = updateValueInArr(this.state[name], langFieldMap)
        }

        // update state.modelOptions
        if(name === 'modelKey' && value){

            api.getOpzioniByModel(value).then(modelOptions => {
                //console.log(movies.data);
                console.log('modelOptions', modelOptions)
                this.setState({
                    modelOptions: modelOptions.data
                })

                let modelOpzioniTree = treeOpzioni.creaStrutturaOpzioni(this.state.modelOptions);
                //console.log('modelOpzioniTree', modelOpzioniTree)
                this.setState({
                    modelOpzioniTree: modelOpzioniTree
                })
            })
        }

        this.setState({
            [name]: value,    });

    }

    handleOnClickComponentModelTree = async event => {
        //console.log('handleonClickcomponentModelTree', event.target.dataset)

        let validEditTarget = ['requires', 'excludes']

        if(!this.state.modalEditTarget || validEditTarget.indexOf(this.state.modalEditTarget) < 0){
            console.warn('state.modalEditTarget not set')
            return
        }

        let id = event.target.dataset.optionId,
            array = this.state[this.state.modalEditTarget]


        let indexOf = array.indexOf(id);
        if(indexOf < 0){
            array.push(id);
        }else {
            array.splice(indexOf, 1);
        }

        this.setState({
            [this.state.modalEditTarget]: array,});

    }

    handleChangeInputRating = async event => {
        const rating = event.target.validity.valid
            ? event.target.value
            : this.state.rating

        this.setState({ rating })
    }

    handleUpdate = async () => {
        const { id, modelKey, tipo, gruppo, rank, nameArr, descriptionArr, noteArr, price, isMultiple, isRequired, isSelectable, isStandard, requires, excludes, useAccordion, isFixed } = this.state
        let payload = { modelKey, tipo, gruppo, rank, nameArr, descriptionArr, noteArr, price, isMultiple, isRequired, isSelectable, isStandard, requires, excludes, useAccordion, isFixed }


        console.log('payload', payload)
        console.log('payload excludes', payload.excludes)
        console.log('payload requires', payload.requires)

        // patch per togliere valori null in excludes e requires

        payload.excludes = payload.excludes.filter(function (el) {
            return el != null;
        });

        payload.requires = payload.requires.filter(function (el) {
            return el != null;
        });

        console.log('payload requires', payload.requires)
        //return

        //console.log('isNewRecord', this.state.isNewRecord)


        if(this.state.isNewRecord){
            // insert new
            //payload.modelKey = this.state.modelKey

            await api.insertOpzione(payload).then(res => {
                //window.alert(`Movie updated successfully`)
                window.location.href = `/options/list/${this.state.modelKey}`

            })
        }else {
            // update
            await api.updateOpzioneById(id, payload).then(res => {
                //window.alert(`Movie updated successfully`)
                window.location.href = `/options/list/${this.state.modelKey}`
            })
        }
    }

    componentDidMount = async () => {

        //console.log('componentDidMount')
        const { id } = this.state
        let isNewRecord = true;
        let movie = {}
        movie.data= []
        let modelKey = ''
        let modelOptions = [],
         modelOpzioniTree = [],
            requires = [],
            excludes = [],
            editRequireArray = [],
            tipo = 'opzione'

        //console.log(window.location.search);


        // edit record
        if(id!=='new'){
            isNewRecord = false
            movie = await api.getOpzioneById(id)
            modelKey = movie.data.modelKey

            requires= movie.data.requires
            excludes= movie.data.excludes

            tipo = movie.data.tipo


            //console.log(movie.data.requires);
        }

        if(isNewRecord){
            // modelKey da query string
            const parsed = queryString.parse(window.location.search);
            if(parsed.modelKey) modelKey = parsed.modelKey;
            //console.log('parsed.modelKey',parsed.modelKey);

        }

        if(modelKey){
            modelOptions = await  api.getOpzioniByModel(modelKey)
            modelOptions = modelOptions.data
            //console.log('modelOptions', modelOptions)

            modelOpzioniTree = treeOpzioni.creaStrutturaOpzioni(modelOptions);
            //console.log('modelOpzioniTree', modelOpzioniTree)
        }
        modelOptions = modelOptions.sort(treeOpzioni.compareOptionsByLangText)
        //console.log('sorted modelOptions', modelOptions)

        let  models = await api.getModels() //api.getAllModels()
        //models = models.data
        //console.log('models', models)



        this.setState({
            isNewRecord: isNewRecord,
            modelKey: modelKey,
            gruppo: movie.data.gruppo,
            rank: movie.data.rank,
            tipo: tipo,
            isRequired: movie.data.isRequired,
            isSelectable: movie.data.isSelectable,
            isMultiple: movie.data.isMultiple,
            isStandard: movie.data.isStandard,
            useAccordion: movie.data.useAccordion,
            isFixed: movie.data.isFixed,
            price: movie.data.price == null ? '' : movie.data.price,
            nameArr: movie.data.nameArr,
            descriptionArr: movie.data.descriptionArr,
            noteArr: movie.data.noteArr,
            requires: requires,
            excludes: excludes,
            models: models,
            modelOptions: modelOptions,
            modelOpzioniTree: modelOpzioniTree,
            editRequireArray: editRequireArray,
        })
    }

    render() {

        //console.log(treeOpzioni.lookupOptionById('606d56a929c19f08c54f6c75', this.state.modelOptions))

        const { gruppo, rank, nameArr, descriptionArr, noteArr} = this.state

        return (
            <>

                    <Title>Modifica opzione</Title>

                    <div className="row">
                        <div className="col-12 col-md-4">

                            <div className="form-group">

                                <Label>Modello:</Label>
                                <ModelSelect name="modelKey" value={this.state.modelKey || ''} models={this.state.models} onChange={this.handleInputChange} />

                            </div>

                            <div className="form-group">

                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="tipo" id="tipo1" value="gruppo"
                                           checked={this.state.tipo === "gruppo"}
                                           onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="tipo1">Gruppo</label>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="tipo" id="tipo2" value="opzione"
                                           checked={this.state.tipo === "opzione"}
                                           onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="tipo2">Opzione</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <Label>Gruppo:</Label>

                                <select  className="form-control"
                                         name="gruppo"
                                         value={gruppo || ''}
                                         onChange={this.handleInputChange}>
                                    <option value="">Nessuno</option>

                                    {this.state.modelOptions.map((item, index)=> {
                                        if(item.tipo==='gruppo'){
                                            return(<option value={item._id} key={item._id}>{getTextInLangArr(item.nameArr, this.state.languageArr[0])}</option>
                                        ) } return null
                                    })}
                                </select>
                            </div>
                            <div className="form-group">
                                <div className="form-check">

                                    <Checkbox
                                        type="checkbox"
                                        name="isMultiple"
                                        id="isMultiple"
                                        checked={this.state.isMultiple}
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="isMultiple">Scelta multipla (opzioni o sotto
                                        gruppi)</label>
                                </div>

                                <div className="form-check">

                                    <Checkbox
                                        type="checkbox"
                                        name="isRequired"
                                        id="isRequired"
                                        checked={this.state.isRequired}
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="isRequired">Obbligatorio</label>

                                </div>

                                <div className="form-check">

                                    <Checkbox
                                        type="checkbox"
                                        name="isSelectable"
                                        id="isSelectable"
                                        checked={this.state.isSelectable}
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="isSelectable">Selezionabile</label>

                                </div>

                                <div className="form-check">

                                    <Checkbox
                                        type="checkbox"
                                        name="isStandard"
                                        id="isStandard"
                                        checked={this.state.isStandard}
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="isStandard">Standard (pre-selezionato)</label>

                                </div>
                                <div className="form-check">

                                    <Checkbox
                                        type="checkbox"
                                        name="useAccordion"
                                        id="useAccordion"
                                        checked={this.state.useAccordion}
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="useAccordion">Fisarmonica</label>

                                </div>
                                <div className="form-check">

                                    <Checkbox
                                        type="checkbox"
                                        name="isFixed"
                                        id="isFixed"
                                        checked={this.state.isFixed}
                                        onChange={this.handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="useAccordion">Footer (fisso)</label>

                                </div>

                            </div>
                            <div className="form-group">
                                <Label>Prezzo:</Label>
                                <InputText
                                    type="number"
                                    name="price"
                                    pattern="[0-9]+([,\.][0-9]+)?"
                                    value={this.state.price || ''}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="form-group">

                                <Label>Ordine:</Label>
                                <InputText
                                    type="number"
                                    name="rank"
                                    step="1"
                                    lang="en-US"
                                    min="0"
                                    max="100"
                                    pattern="[0-9]+([,\.][0-9]+)?"
                                    value={rank || ''}
                                    onChange={this.handleInputChange}
                                />
                            </div>

                        </div>
                        <div className="col-12 col-md-8">

                            <div>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={this.state.key}
                                    onSelect={key => this.setState({ key })}
                                >

                                    {this.state.languageArr.map((item, index)=> {

                                        return( <Tab eventKey={item} title={item} key={item}>

                                            <div className="card">
                                                <div className="card-body">

                                                    <LanguageInputText
                                                        name="name"
                                                        languageKey = {item}
                                                        key={item.toString()+'name'}
                                                        value={getTextInLangArr(nameArr, item)}
                                                        index = {index}
                                                        onChange={this.handleInputChange}
                                                    />

                                                    <LanguageInputText
                                                        name="description"
                                                        languageKey = {item}
                                                        key={item.toString()+'description'}
                                                        value={getTextInLangArr(descriptionArr, item)}
                                                        index = {index}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    <LanguageInputText
                                                        name="note"
                                                        languageKey = {item}
                                                        key={item.toString()+'note'}
                                                        value={getTextInLangArr(noteArr, item)}
                                                        index = {index}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                            </div>

                                        </Tab>)
                                    })}

                                </Tabs>

                            </div>

                            <div className="row">
                                <div className="col-12">

                                    <Card border="white">
                                        <Card.Body>
                                            <Card.Title>Richiede</Card.Title></Card.Body>


                                        {this.state.requires.map((item, index)=> {
                                            //console.log('item',item)
                                            return(<Card className="mb-4" key={index}>
                                                <Card.Body>
                                                <ListGroup className="list-group-flush" key={index}><RequiredOpzione items={item} indexInArray={index} modelOptions={this.state.modelOptions} />
                                            </ListGroup>
                                                    <div className="text-right">
                                                    <ButtonSecondary onClick={this.deleteRequire.bind(null, index)}>
                                                        Cancella
                                                    </ButtonSecondary>
                                                    </div>

                                                </Card.Body></Card>)

                                        })}


                                    </Card>


                                    <Card >
                                        <Card.Body>

                                    {this.state.modelOptions && this.state.modelOptions.map((item, index)=> {
                                        return(<EditRequire key={index} element={item} selectedValues={this.state.editRequireArray} onChange={this.handleEditRequireChange}></EditRequire>)
                                    })}
                                            <div className="text-right mt-3">
                                    <ButtonPrimaryOutline onClick={this.addRequire.bind(null, null)}>
                                        Aggiungi richiede
                                    </ButtonPrimaryOutline>
                                            </div>
                                        </Card.Body>
                                    </Card>


                                </div>

                                <div className="col-12">

                                    <Card className="mt-3">
                                        <Card.Body>
                                            <Card.Title>Esclude</Card.Title>


                                    <ol>
                                    {this.state.excludes.map((item, index)=> {
                                        let label = item,
                                            opzione = treeOpzioni.lookupOptionById(item, this.state.modelOptions)
                                        if(opzione) label = opzione.nameArr[0].text
                                        return(
                                            <li key={index} className="">{label}</li>
                                        )
                                    })}

                                    </ol>
                                    <input type="hidden"
                                           className="form-control input-arr"
                                           name="excludes"
                                           value={this.state.excludes}
                                           onChange={this.handleInputChange}
                                    />

                                            <div className="text-right mt-3">
                                                <ButtonPrimaryOutline onClick={this.openModal.bind(null, 'excludes')}>
                                                    Aggiungi Esclude
                                                </ButtonPrimaryOutline>
                                            </div>


                                        </Card.Body></Card>

                                </div>
                            </div>



                        </div>
                    </div>

                    <Button onClick={this.handleUpdate}>Salva</Button>
                    <CommonComponents.CancelButton href={this.state.modelKey ? '/options/list/' + this.state.modelKey : '/models/list'}>Annulla</CommonComponents.CancelButton>

                    {/* modal start  */}

                    <Modal show={this.state.isOpen} onHide={this.closeModal} size="lg" scrollable={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.modalEditTarget} Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="option-component-container">

                                {this.state.modelOpzioniTree.map((item, index)=> {
                                    return(<OpzioneComponent filterSelectable="true" onClick={this.handleOnClickComponentModelTree.bind(this)} key={item._id} selectedElements={this.state.modalEditTarget==='requires' ? this.state.requires : this.state.excludes} element={item}></OpzioneComponent>
                                    )
                                })}


                            </div>
                            </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* modal end  */}


            </>
        )
    }
}

export default OpzioneUpdate
