import React, { Component } from 'react'
import api from '../api'
import { useAuth } from "../context/auth";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import styled from "styled-components";
import FormatCurrency from '../utilities/FormatCurrency'
import FormatDate from '../utilities/FormatDate'
import Utilities from '../utilities/Utilities'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import '../style/commons.css'

import { withTranslation } from 'react-i18next'
import treeOpzioni from "../utilities/treeOpzioni";


const Update = styled.a`
    color: #ef9b0f;
    cursor: pointer;
`

const Delete = styled.a`
    color: #ff0000!important;
    cursor: pointer;
`


const InputText = styled.input.attrs({})`
    
    className: ${props => props.className};
`

class CreaPreventivo extends Component {
    updateModel = event => {
        event.preventDefault()

        //window.location.href = `/preventivo/${this.props.id}`
        window.location.href = `/preventivo/?modelKey=${this.props.modelKey}`
    }

    render() {
        return <Dropdown.Item className="dropdown-item" href="#" onClick={this.updateModel}><span className="text-uppercase">{this.props.label}</span></Dropdown.Item>
    }
}

class DeletePreventivo extends Component {

    deletePreventivo = event => {

        console.log('DeletePreventivo')
        event.preventDefault()

        if (
            window.confirm(
                `Do you want to delete the quotation ${this.props.id} permanently?`,
            )
        ) {
            api.deletePreventivoById(this.props.id)
            window.location.reload()
        }
    }

    render() {
        return <Delete onClick={this.deletePreventivo}>{this.props.label}</Delete>
    }
}

class EditPreventivo extends Component {
    updateModel = event => {
        event.preventDefault()

        //window.location.href = `/preventivo/${this.props.id}`
        window.location.href = `/preventivo/?id=${this.props.id}`
    }

    render() {
        return <Update onClick={this.updateModel} className={this.props.className}>{this.props.label}</Update>
    }
}

class SelectRicerca extends Component {

    render() {
        return <>
        <Form.Control as="select"
                      onChange={this.props.onChange}
                      name={this.props.name}
                      value={this.props.value}
                      className={this.props.className}>
            <option value="">{this.props.label}</option>
            {this.props.items && this.props.items.map((item, index)=> {
                return(<option key={index} value={item._id}>{item._id}</option>) })}
        </Form.Control>
        </>
    }
}

class PreventiviList extends Component {

    constructor(props) {
        super(props)

        let userId = null,
            isAdmin = false
        if( this.props.authTokens){
            userId =  this.props.authTokens.email
            isAdmin = this.props.authTokens.isAdmin
        }
        //console.log('constructor userId: ' + userId, isAdmin);


        this.state = {
            preventivi: [],
            usersList: [],
            modelsList : [],
            preventviModelsList : [],
            isLoading: false,
            isAdmin: isAdmin,
            userId: userId,
            searchModelKey:'',
            searchUserId:'',
            startDate : '',
            endDate : ''
        }

        this.handleSeachInputChange = this.handleSeachInputChange.bind(this);
        this.find = this.find.bind(this);
        this.resetSearch = this.resetSearch.bind(this)


    }

    calcolaPrezzoNetto(item){
        //console.log('calcolaPrezzoNetto', this.state.discount)
        //return Number(this.state.price) + Number(this.state.discount) + Number(this.state.freightCharges) + Number(this.state.tradeInValue)
        return Number(item.price) - Number(item.discount) + Number(item.freightCharges) - Number(item.tradeInValue)
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })


        /*
        await api.getAllModels().then(modelsList => {
            //console.log(modelsList.data);
            this.setState({
                modelsList: modelsList.data,
            })
        })*/

        await api.getModels().then(modelsList => {
            //console.log(modelsList);
            this.setState({
                modelsList: modelsList,
            })
        })

        await api.getPreventiviUsers().then(usersList => {
            //console.log(usersList.data);
            this.setState({
                usersList: usersList.data,
            })
        })

        //preventviModelsList
        await api.getPreventiviModels().then(preventviModelsList => {
            //console.log(preventviModelsList.data);
            this.setState({
                preventviModelsList: preventviModelsList.data,
            })
        })


        this.state.isAdmin && await api.getAllPreventivi().then(preventivi => {
            //console.log(preventivi.data);
            this.setState({
                preventivi: preventivi.data,
                isLoading: false,
            })
        })

        !this.state.isAdmin && await api.getPreventiviByUser(this.state.userId).then(preventivi => {
            //console.log(preventivi.data);
            this.setState({
                preventivi: preventivi.data,
                isLoading: false,
            })
        })
    }


    handleSeachInputChange(event) {
        //console.log("handleSeachInputChange")

        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        //console.log(name, value)
        //console.log('this.state.modelKey', this.state.modelKey)

        this.setState({
            [name]: value,
            }, () => {if(target.type === 'select-one') this.find()}
        );

    }

    resetSearch(){
        this.setState({
            searchUserId: '',
            searchModelKey: '',
            searchQuery: '',
            startDate : '',
            endDate : ''
        })
    }


    setStartDate(date){
        //console.log('setStartDate', date)
        this.setState({
            startDate: date,
        })
    }

    setEndDate(date){
        //console.log('end', date)
        this.setState({
            endDate: date,
        })
    }


    find = async () => {
        const DATE_FORMAT = "yyyy-MM-DD"
        let { searchModelKey, searchQuery } = this.state
        let payload = { searchModelKey, searchQuery }

        let searchUserId = this.state.isAdmin ?  this.state.searchUserId : this.state.userId
        payload.searchUserId =  searchUserId

        if(this.state.startDate){
            payload.startDate = Utilities.formatDate(this.state.startDate, DATE_FORMAT)
        }
        if(this.state.endDate){
            payload.endDate = Utilities.formatDate(this.state.endDate, DATE_FORMAT)
        }

        //console.log('startDate', Utilities.formatDate(this.state.startDate, "yyyy/MM/DD"))

        //console.log('find paylod', payload)

        await api.findPreventivi(payload).then(res => {

            //console.log(res.data)
            this.setState({
                preventivi: res.data,
                isLoading: false,
            })
        })

    }

    render() {

        const { t, i18n } = this.props;
        const { preventivi } = this.state

        let showData = true

        if (!preventivi.length) {
            showData = false
        }

        let SelectRicercaUser = <></>

        if (this.state.isAdmin) {

            SelectRicercaUser = <SelectRicerca
                value={this.state.searchUserId}
                name="searchUserId"
                label={t('utente')}
                onChange={this.handleSeachInputChange}
                items={this.state.usersList}
            />

        }


        return (

                <>
                    <div className="row mb-1">
                        <div className="col-6"><h1>{t('preventivi')}</h1></div>

                        <div className="col-6 text-right">

                            <DropdownButton id="dropdown-basic-button" title={t('nuovo')}>
                                {(
                                    this.state.modelsList.map((item, index)=> {
                                        return(
                                            <CreaPreventivo key={index} modelKey={item.key} label={treeOpzioni.getTextInLangArr(item.nameArr, i18n.language)} />
                                        )
                                    })
                                )}
                            </DropdownButton>

                        </div>
                    </div>


                        <div className="form-row">


                            <div className="form-group col-12 col-md-2">
                                {SelectRicercaUser}
                            </div>

                            <div className="form-group col-12 col-md-2">

                                <SelectRicerca
                                    value={this.state.searchModelKey}
                                    name="searchModelKey"
                                    label={t('modello')}
                                    onChange={this.handleSeachInputChange}
                                    items={this.state.preventviModelsList}
                                    className="text-uppercase"
                                />

                            </div>

                            <div className="form-group col-12 col-md-2">


                                <>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={date => this.setStartDate(date)}
                                        selectsStart
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        dateFormat="dd/MM/yyyy"
                                        className={"form-control"}
                                        placeholderText={t('searchStartDate')}
                                    />
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onChange={date => this.setEndDate(date)}
                                        selectsEnd
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        minDate={this.state.startDate}
                                        dateFormat="dd/MM/yyyy"
                                        className={"form-control"}
                                        placeholderText={t('searchEndDate')}
                                    />
                                </>



                            </div>

                            <div className="form-group col-12 col-md-2">
                                <InputText
                                    type="text"
                                    name="searchQuery"
                                    id="searchQuery"
                                    value={this.state.searchQuery || ''}
                                    onChange={this.handleSeachInputChange}
                                    className={"form-control"}
                                    placeholder={t('searchQuery')}
                                />
                            </div>

                            <div className="form-group col-12 col-md-3">
                                <Button variant="secondary" onClick={this.find}>{t('cerca')}</Button>
                                <Button className="ml-3" variant="outline-secondary" onClick={this.resetSearch}>{t('reset')}</Button>
                            </div>


                        </div>




                    {showData && (

                        this.state.preventivi.map((item, index)=> {
                            //console.log(this.calcolaPrezzoNetto(item))

                            return(
                                <div className="row" key={index}>
                                    <div className="col p-2 border-top">

                                        <div className="row">

                                        </div>

                                        <div className="row">

                                            <div className="col-12 col-md-4">
                                                <strong className="text-uppercase">
                                                <EditPreventivo id={item._id} label={item.customerLastName + ' ' + item.customerFirstName} className="text-primary" /></strong>
                                                <br />{item.customerEmail}
                                                <br /><small>{item.name}</small>
                                                </div>

                                            <div className="col-12 col-md-3"><FormatDate value={item.created_at}/>
                                                <br /><small><FormatDate value={item.updated_at} showTime={true}/></small>
                                                <br /><small>{item.userId}</small>
                                            </div>
                                            <div className="col-12 col-md-3"><strong className="text-uppercase">{item.modelKey}</strong></div>
                                            <div className="col-12 col-md-2 text-right"><FormatCurrency value={item.price} />
                                                <p><em><FormatCurrency value={this.calcolaPrezzoNetto(item)} /></em></p>

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <EditPreventivo id={item._id} label={t('modifica')} />&nbsp;
                                                {this.state.isAdmin &&  <DeletePreventivo id={item._id} label={t('cancella')} />}


                                                &nbsp;<a href={'/pdf?id=' + item._id} >PDF</a>




                                                </div>
                                        </div>

                                    </div>
                                </div>

                            )
                        })
                    )}

                </>

        )
    }
}

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const auth = useAuth();
        //console.log('withMyHook authTokens', auth)
        return <Component {...props} authTokens={auth.authTokens} />;
    }
}

export default withTranslation('translations')(withMyHook(PreventiviList))