import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import api from '../api'
import OpzioneComponent from '../components/Opzione'
import treeOpzioni from '../utilities/treeOpzioni'

import '../style/optionComponent.css'


class CreateOpzione extends Component {
    createOpzione = event => {
        event.preventDefault()
        window.location.href = `/options/update/new?modelKey=${this.props.modelKey}`
    }

    render() {
        return <Button onClick={this.createOpzione}>Nuova opzione</Button>
    }
}

class OpzionesList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modelKey: this.props.match.params.id,
            opzioni : [],
            movies: [],
            columns: [],
            modelsList:[],
            isLoading: false,
        }
    }

    updateOpzione = event => {
        event.preventDefault()
        //console.log(event.target.dataset.optionId)
        window.location.href = `/options/update/${event.target.dataset.optionId}`
    }

    onClickDuplica = async event => {
        event.preventDefault()
        //console.log('modelkey', event.target.dataset.modelkey)
        let targetModelKey = event.target.dataset.modelkey

        //TODO
        //controllo e avvisare l'utente se il modello target ha già delle opzioni
        let targetModelOptionsCount = 0
        await api.getOpzioniByModel(targetModelKey).then(movies => {

            if(movies && movies.data) targetModelOptionsCount = movies.data.length;

        })
        if(targetModelOptionsCount > 0){
            window.alert(
                `Il modello ${targetModelKey} ha già ${targetModelOptionsCount}`,
            )
            return
        }


        if (
            targetModelKey && window.confirm(
                `Sei sicuro di voler duplicare tutte le opzioni del modello ${this.state.modelKey} nel modello ${targetModelKey}?`,
            )
        ) {
           console.log('procedo con la duplicazione')
            treeOpzioni.duplicaOpzioniModello(this.state.modelKey, targetModelKey)
        }

        //window.location.href = `/options/update/${event.target.dataset.optionId}`
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })

        const { modelKey } = this.state
        //console.log('id', id)
        let modelsList = []

        await api.getModels().then(data=>{
            //console.log('data', data)
            modelsList = data
            //console.log('modelsList', modelsList)
        })

        await api.getOpzioniByModel(modelKey).then(movies => {
            //console.log(movies.data);

            let opzioni = treeOpzioni.creaStrutturaOpzioni(movies.data);
            this.setState({
                opzioni : opzioni,
                movies: movies.data,
                modelsList: modelsList,
                //isLoading: false,
            })
        })

    }

    render() {
        //const { movies } = this.state
        //console.log('TCL: OpzionesList -> render -> movies', movies)

        /*
        let showTable = true

        if (!movies.length) {
            showTable = false
        }
        */
        return (
            <>

                <h1 className="my-4">{this.state.modelKey}</h1>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <CreateOpzione modelKey={this.state.modelKey}></CreateOpzione>
                    </div>
                    <div className="col-12 col-lg-6 text-lg-right">

                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                Duplica opzioni modello
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.ItemText><small>Seleziona un modello</small></Dropdown.ItemText>
                                <Dropdown.Divider />
                                {this.state.modelsList.filter(item=> item.key!==this.state.modelKey).map((item, index    )=> {
                                    return(<Dropdown.Item
                                            onClick={this.onClickDuplica.bind(this)}
                                            data-modelkey={item.key}
                                            key={index}
                                            >{item.name}</Dropdown.Item>
                                    )
                                })}

                            </Dropdown.Menu>
                        </Dropdown>


                    </div>
                </div>

                <div className="option-component-container my-5">

                    {this.state.opzioni.map((item, index    )=> {
                        return(<OpzioneComponent
                                onClick={this.updateOpzione.bind(this)}
                                data-option-id={item._id}
                                key={item._id}
                                element={item}></OpzioneComponent>
                        )
                    })}
                </div>

            </>
        )
    }
}

export default OpzionesList