import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import FormatCurrency from '../utilities/FormatCurrency'
import { withTranslation } from 'react-i18next'
import treeOpzioni from "../utilities/treeOpzioni";

class ConfigurationErrorRequireInput extends Component{



    render (){

        //console.log(this.props.option)
        //let html = this.props.option.nameArr[0].text,
        let
            fieldId = 'checkbox-' + this.props.option._id,
            checked = (this.props.selectedItems && this.props.selectedItems.find(element => element === this.props.option._id)) ? 'checked' :'',
            disabled= this.props.disabled  ? 'disabled' : ''


    return (<div className="form-check">
        <input className="form-check-input" data-checkerroridx={this.props.checkErrorIdx} type="checkbox" value={this.props.option._id} checked={checked} disabled={disabled} id={fieldId} onChange={this.props.onChange}/>
        <label className="form-check-label" htmlFor={fieldId}>{this.props.htmlLabel} <FormatCurrency value={this.props.option.price}/><small className="d-none"> {this.props.option._id}</small></label>
    </div>)
    }

}

class ConfigurationErrorMessage extends Component{
    constructor(props) {
        super(props)

        let optionArrToUnselect = [],
            optionArrToSelect = [],
            // errori che devono essere selezionati (campi required multivalore)
            errorsCheckMap = {}



        this.props.configurationErrors && this.props.configurationErrors.forEach((item, index)=> {
            //console.log('item', item)
            if(item.type === 'excludes') optionArrToUnselect = optionArrToUnselect.concat(item.whatArr)

            // imposta in optionArrToSelect gli elementi require con una sola opzione
            if(item.type === 'require' && item.whatArr && item.whatArr.length === 1){
                //console.log('--------require', item.whatArr[0])
                optionArrToSelect.push(item.whatArr[0])
            }

            // imposta i valori require multipli che devono essere selezionati (almeno 1)

            if(item.type === 'require' && item.whatArr && item.whatArr.length > 1){
                errorsCheckMap['idx_' + index] = []
            }

        })



        //console.log('errorsCheckMap', errorsCheckMap)
        //console.log('optionArrToUnselect', optionArrToUnselect)
        //console.log('optionArrToSelect', optionArrToSelect)


        this.state = {
            optionArrToUnselect : optionArrToUnselect,
            optionArrToSelect : optionArrToSelect,
            errorsCheckMap: errorsCheckMap
        }

        this.handleEditRequireChange = this.handleEditRequireChange.bind(this);
        this.handleConferma = this.handleConferma.bind(this);
    }


    handleEditRequireChange(event) {
        const target = event.target;
        //console.log('state', this.state)
        let value = target.value,
            newOptionArrToSelect =[...this.state.optionArrToSelect],
            newErrorsCheckMap = Object.assign({}, this.state.errorsCheckMap),
            checkErrorIdx = "idx_" + target.getAttribute('data-checkerroridx');

        //console.log('checkErrorIdx', checkErrorIdx)
        //console.log('newErrorsCheckMap', newErrorsCheckMap[checkErrorIdx])
        if(!newErrorsCheckMap[checkErrorIdx]) newErrorsCheckMap[checkErrorIdx] = []

        if(target.checked) {
            // checked

            newOptionArrToSelect.push(value)
            newErrorsCheckMap[checkErrorIdx].push(value)
        }else{
            // non checked

            let index = newOptionArrToSelect.findIndex(function(o){
                return o === value;
            })
            if (index !== -1) newOptionArrToSelect.splice(index, 1);

            // elimino valore anche da newErrorsCheckMap[checkErrorIdx]
            index = newErrorsCheckMap[checkErrorIdx].findIndex(function(o){
                return o === value;
            })
            if (index !== -1) newErrorsCheckMap[checkErrorIdx].splice(index, 1);

        }

        //console.log('newErrorsCheckMap', newErrorsCheckMap[checkErrorIdx])
        //console.log('newOptionArrToSelect',newOptionArrToSelect)
        this.setState({optionArrToSelect: newOptionArrToSelect, newErrorsCheckMap: newErrorsCheckMap})

    }

    handleConferma = function(event){
        //console.log('handleConferma', this.state)

        // controllo se ...

        let newErrorsCheckMap = Object.assign({}, this.state.errorsCheckMap),
            hasErrors = false


        for (const [key, value] of Object.entries(newErrorsCheckMap)) {
            console.log(key, value);
            if(!value || value.length < 1){
                console.log('error in', key);
                hasErrors = true
            }
        }
        if(hasErrors) {
            this.setState({hasErrors : hasErrors})
            return
        }

        let argMap = {elementId: this.props.element._id, optionArrToUnselect: this.state.optionArrToUnselect, optionArrToSelect: this.state.optionArrToSelect}
        this.props.handleConfermaConflitto(event, argMap)
    }



    render() {

        //console.log('this.props.configurationErrors', this.props.configurationErrors)

        const { t } = this.props;
        //console.log('this.props.configurationErrors language', this.props.language)



        return(
            <div className="card border-warning shadow my-3">
                <h5 className="card-header  border-warning bg-warning text-center">{this.props.title}</h5>
                <div className="card-body">
                    <ul className="list-group list-group-flush border-warning">
                        {this.props.configurationErrors && this.props.configurationErrors.map((item, index)=> {


                            //console.log('item',item)
                            //console.log(treeOpzioni.getTextInLangArr(item.whoOption.nameArr, this.props.language))
                            // console.log(item.whatOptionArr.map)

                            let
                                errorsCheck = this.state.errorsCheckMap["idx_" + index],
                                hasError = this.state.hasErrors && errorsCheck && errorsCheck.length < 1 ? true : false,
                                errorAlert =  <Alert className="mb-1" variant="danger">{hasError ? t('confermaModificaErrore') : ' '}</Alert>,
                                whoLabel = treeOpzioni.getTextInLangArr(item.whoOption.nameArr, this.props.language)


                            return(<li key={index}
                                       className="list-group-item text-secondary border-warning">
                                    <h6 className="mb-1">{whoLabel}</h6>
                                    <p className="mb-1">{t(item.type + "_t")}</p>

                                    {item.whatOptionArr.map((option, index2)=> {
                                        let whatLabel = treeOpzioni.getTextInLangArr(option.nameArr, this.props.language)
                                        return(<h6 key={index2} >
                                            {item.type === 'require' ? <ConfigurationErrorRequireInput checkErrorIdx={index} disabled={(item.whatOptionArr && item.whatOptionArr.length <= 1) ? true: false} option={option} onChange={this.handleEditRequireChange} selectedItems={this.state.optionArrToSelect} htmlLabel={whatLabel}/> : <>{whatLabel} <FormatCurrency value={option.price}/><small className="d-none"> {option._id}</small></>}
                                        </h6>)
                                    })}

                                    {hasError ? errorAlert : ''}

                                </li>
                            )

                        })}
                    </ul>
                    <p className="text-center">

                        <Button
                            onClick={this.handleConferma}
                            variant="success"
                            className="mx-1">{t('confermaModifica')}</Button>

                        <Button
                            onClick={this.props.handleAnnullaConfermaConflitto}
                            variant="secondary"
                            className="mx-1">{t("annulla")}</Button>

                    </p>
                </div>
            </div>
        )
    }
}

export default withTranslation('translations')(ConfigurationErrorMessage)