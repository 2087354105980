import React from "react";
import CommonComponents from '../components/CommonComponents'


function Admin(props) {




    return (
        <CommonComponents.Wrapper>
            <div className="container my-5">
                <h1>Gestione utenti</h1>
                <p>coming soon...</p>

            </div>
        </CommonComponents.Wrapper>
    );
}

export default Admin;