import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Card, Form, Input, Button, Error } from "../components/AuthForm";
import { useAuth } from "../context/auth";
import api from "../api";
//import { withTranslation } from 'react-i18next'



function Login() {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const { setAuthTokens } = useAuth();

    //const { i18n } = withTranslation()



    function postLogin() {

        let payload = {email: userName, password: password}

         api.userLogin(payload).then(res => {
             if (res.status === 200) {
                 //console.log('res data',res.data)
                 setAuthTokens(res.data);
                 setLoggedIn(true);


             } else {
                 setIsError(true);
             }

        }).catch(e => {
             setIsError(true);
         });

    }

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <Card>

            <h1>Login</h1>
            <Form onSubmit={ e => { postLogin(); e.preventDefault(); }}>
                <Input
                    type="username"
                    value={userName}
                    onChange={e => {
                        setUserName(e.target.value);
                    }}
                    placeholder="email"
                />
                <Input
                    type="password"
                    value={password}
                    onChange={e => {
                        setPassword(e.target.value);
                    }}
                    placeholder="password"
                />
                <Button  type="submit">Sign In</Button>
            </Form>
            {1<0 && <Link to="/signup">Don't have an account?</Link>}
            { isError &&<Error>The username or password provided were incorrect!</Error> }
        </Card>
    );
}

export default Login;