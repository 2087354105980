import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'


const List = styled.div.attrs({
    className: 'navbar-nav mr-auto',
})``

const Item = styled.div.attrs({
    className: ' text-uppercase',
})``

class Links extends Component {



    render() {
        //https://configurator.sacsmarine.it/configurator/#!page=model&modelId=rebel-47

        const { t } = this.props;
        /*
        const changeLanguage = (lng) => {
            //console.log('changeLanguage', lng)
            i18n.changeLanguage(lng);
        };
        */
        //console.log('links props', this.props)


        let ModelliItem = <></>
        let PreventiviItem = <></>
        let UtentiItem = <></>

        if(this.props.authTokens ){

            PreventiviItem =  <Item>
                <Link to="/preventivi/list" className="nav-link" onClick={this.props.onSelect}>
                    {t('preventivi')}
                </Link>
            </Item>


            if(this.props.authTokens.isAdmin){

                UtentiItem = <Item>
                    <Link to="/users/list" className="nav-link" onClick={this.props.onSelect}>
                        {t('utenti')}
                    </Link>
                </Item>

                ModelliItem = <Item>
                    <Link to="/models/list" className="nav-link" onClick={this.props.onSelect}> {t('modelli')}</Link>
                </Item>
            }

        }


        return (


                    <List>
                        {UtentiItem}
                        {ModelliItem}
                        {PreventiviItem}

                        <Item>
                            <Link to={{ pathname: "https://configurator.sacsmarine.it/" }} target="configurator" className="nav-link"  onClick={this.props.onSelect}>
                                {t('configuratore')}
                            </Link>
                        </Item>
                    </List>


        )
    }
}

export default withTranslation('translations')(Links)