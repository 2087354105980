import React, { Component } from 'react'
import styled from 'styled-components'

import logo from '../logo.png'

const Wrapper = styled.a.attrs({
    className: 'navbar-brand',
})``

class Logo extends Component {
    render() {
        return (
            <Wrapper href="https://sacsmarine.it" target="configurator">
                <img src={logo} width="128" height="48" alt="sacsmarine.it" />
            </Wrapper>
        )
    }
}

export default Logo