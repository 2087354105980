import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import {BsCheckBox, BsPlusSquare, BsShieldShaded} from 'react-icons/bs'

// icone
//https://react-icons.github.io/react-icons/icons?name=bs

class OpzioneComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            element : this.props.element,
            selectedElements : this.props.selectedElements,
        }
    }

     isSelectableIcon =  () => {
        if(this.props.element.isSelectable) return <BsCheckBox/>
        return ''
    }

    isMultipleIcon =  () => {
        if(this.props.element.isMultiple) return <BsPlusSquare/>
        return ''
    }

    isRequiredIcon =  () => {
        if(this.props.element.isRequired) return <BsShieldShaded/>
        return ''
    }


    render() {
        let className = 'option',
            containerClassName = '',
            selectedClassName = '',
            disabledClassName = ''

        if (this.props.element.tipo === 'gruppo') {
            className = 'gruppo';
            containerClassName = 'group-container'
        }

        if(this.props.selectedElements && this.props.selectedElements.includes(this.props.element._id)){
            selectedClassName = ' selected'
        }


        if(!this.props.element.isSelectable && this.props.filterSelectable) disabledClassName = ' disabled'



        //let dspPrice = '';
        //if(this.state.element.price && !isNaN(this.state.element.price)) dspPrice = ' [' + this.state.element.price + ']';


        return (
            <div className={"option-component " + containerClassName + selectedClassName}  id={"opzione_" + this.props.element._id}>
                <span data-option-id={this.props.element._id} onClick={(!this.props.element.isSelectable && this.props.filterSelectable) ? null : this.props.onClick} className={className + disabledClassName + (this.props.element.isStandard ? ' standard' : '')}>
                    {this.isSelectableIcon()}{this.isMultipleIcon()}{this.isRequiredIcon()}&nbsp;

                    {this.props.element.nameArr[0].text} {this.props.element.isStandard ? '(*Standard*)' : ''}
                 &nbsp;<em><NumberFormat value={this.state.element.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator="," prefix={'€'} /></em>
                    &nbsp;[{this.props.element.rank}]
                    &nbsp;<small>{this.props.element.requires.length >0 ? ('r:' + this.props.element.requires.length): ''}
                    {this.props.element.excludes.length > 0 ?' e:' + this.props.element.excludes.length : ''}</small>
                </span>

                {this.state.element.children && this.state.element.children.map((item, index)=> {
                    return(<OpzioneComponent filterSelectable={this.props.filterSelectable} onClick={this.props.onClick} key={item._id} element={item} selectedElements={this.props.selectedElements}></OpzioneComponent>
                    )
                })}
            </div>)
    }
}


export default OpzioneComponent