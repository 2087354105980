import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

export const userLogin = payload => api.post(`/api/users/login`, payload)


export const insertOpzione = payload => api.post(`/opzioni`, payload)
export const getAllOpzioni = () => api.get(`/opzioni`)
export const getOpzioniByModel = id => api.get(`/opzioni/by-model/${id}`)

// diventa get getModelsCategories
export const getAllModels = async () => {
    return await axios.get(process.env.REACT_APP_MODELS_LIST_URL);
};



export const getModels=()=>{
    return fetch('/models.json'
        ,{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    ).then((response) => response.json());

}

export const getModelByKey=(key)=>{

    return getModels().then((response) => {return response ? response.find(model=>model.key === key) : {}});
}




export const updateOpzioneById = (id, payload) => api.patch(`/opzioni/${id}`, payload)
export const deleteOpzioneById = id => api.delete(`/opzioni/${id}`)
export const getOpzioneById = id => api.get(`/opzioni/${id}`)


export const insertPreventivo = payload => api.post(`/configurazioni`, payload)
export const getAllPreventivi = () => api.get(`/configurazioni`)
export const getPreventiviByModel = id => api.get(`/configurazioni/by-model/${id}`)
export const getPreventiviByUser = id => api.get(`/configurazioni/by-user/${id}`)
export const updatePreventivoById = (id, payload) => api.patch(`/configurazioni/${id}`, payload)
export const deletePreventivoById = id => api.delete(`/configurazioni/${id}`)
export const getPreventivoById = id => api.get(`/configurazioni/${id}`)
export const getPreventiviUsers = () => api.get(`/configurazioni/users`)
export const getPreventiviModels = () => api.get(`/configurazioni/models`)
export const findPreventivi = payload => api.post(`/configurazioni/find`, payload)

export const getAllUsers = () => api.get(`/api/users`)
export const getUserById = id => api.get(`/api/users/${id}`)
export const getUserByUsername = email => api.get(`/api/users/username/${email}`)
export const insertUser = payload => api.post(`/api/users`, payload)
export const updateUser = (id, payload) => api.patch(`/api/users/${id}`, payload)


export const findCrmContactsByEmail = email => api.get(`/crmContacts/search/${email}`)
export const findCrmContactsAll = email => api.get(`/crmContacts`)
export const updateCrmContact = (email, payload) => api.patch(`/crmContacts/update/${email}`, payload)

const apis = {
    userLogin,
    insertOpzione,
    getAllOpzioni,
    getOpzioniByModel,
    updateOpzioneById,
    deleteOpzioneById,
    getOpzioneById,
    getAllModels,
    getModels,
    getModelByKey,
    getAllPreventivi,
    insertPreventivo,
    getPreventiviByModel,
    getPreventiviByUser,
    updatePreventivoById,
    deletePreventivoById,
    getPreventivoById,
    getPreventiviUsers,
    getPreventiviModels,
    findPreventivi,
    getAllUsers,
    getUserById,
    insertUser,
    updateUser,
    getUserByUsername,
    findCrmContactsByEmail,
    findCrmContactsAll,
    updateCrmContact
}



export default apis