export const TRANSLATIONS_EN = {
    welcome:"Welcome to the tutorial",
    indietro :'back',
    preventivo :"quotation",
    preventivi :"quotations",
    modello :"model",
    modelli :"models",
    opzione:'option',
    opzioni:'options',
    utente:"user",
    utenti:"users",
    configuratore:"configurator",
    nuovo:"new",
    nuovo_preventivo:"new quotation",
    aggiungi_opzione:"add option",
    modifica: "edit",
    cancella:"delete",
    salva:"save",
    annulla:"cancel",
    isRequired:"is required",
    excludes: "excluded by",
    requires:"required by",
    require_t:"requires",
    excludes_t: "excludes",
    isRequired_t: "is required",
    confermaModifica: "Confirm",
    invalidEmail:"non è un indirizzo email valido",
    confermaModificaErrore: "Select at least one option",
    configurationErrorMessageTitle:"Please note",
    customer: "customer",
    crmCode:"CRM code",
    email:"email",
    address:"address",
    phone:"phone",
    phoneAreaCode: "area code",
    city: "town",
    zipCode: "zip",
    country: "country",
    customerFirstName:"customer first name",
    customerLastName:"customer last name",
    customerEmail:"customer email",
    customerAddress:"customer address",
    customerPhone:"customer phone",
    customerOwnedBoat:"owned boat",
    customerNavigationArea:"navigation area",
    notes:"quotation notes",
    titoloPreventivo:"title",
    discount:"discount",
    freightCharges:"freight charges",
    tradeInValue:"trade-in",
    tradeInDescription:"trade-in description",
    validity:"validity",
    delivery:"delivery",
    paymentTerms:"payment",
    condizioniCommerciali:"Additional commercial conditions for purchase proposal formulation",
    condizioniVendita:"Sales terms",
    prezzoTotale:"Total price VAT excluded",
    prezzoNetto:"Net Price VAT excluded",
    cerca:"search",
    searchQuery: "search text",
    searchStartDate : "start date",
    searchEndDate : "end date",
    nomeUtente: "username",
    customData: "personalized text for pdf",
    userFormPasswordHelpText: "leave blank to not edit",
    data:"date",
    luogo:"place",
    firma:"signature",
    dealer: "dealer",
    ragioneSociale:"company name",
    crmUserId:"CRM User Id",
    crmContactSearch:"email address",
    crmSearchButton: "search",
    crmContactOwnerState_conflict: 'contact owner doesn\'t match',
    crmContactOwnerState_match: 'contact owner match',
    crmContactOwnerState_unset: 'contact owner unset',
};