import api from '../api'
/**
 * cerca in arr option per id
 */
const lookupOptionById = function (id, arr) {
    if(!id || !arr) {
        //console.log('lookupOptionById invalid id ' + id + ' or array ', arr)
        return
    }

    for (let k = 0; k < arr.length; k++){
        //console.log(arr[k]);
        if(arr[k]._id === id) return arr[k]

    }
}

const convalidaPreventivo = function (opzioniModello, selectedOptions) {
    //console.log('treeOpzioni convalidaPreventivo opzioniModello', opzioniModello)
    //console.log('treeOpzioni convalidaPreventivo selectedOptions', selectedOptions)

    let errors=[],
        requiredErrors=[]


    /*
    esempio error

    {   type : requires | excludes | isRequired
        who: id opzione che richiede
        what: id opzione richiesta
        whoOption : opzione che richiede
        whatOption : opzione richiesta
    }

     */


    const getSelectedOptionsInModel = function() {
        let arr = []
        for (let k = 0; k< selectedOptions.length; k++){
            //console.log('treeOpzioni  selectedOptions', selectedOptions[k])
            let optionId = selectedOptions[k].text && selectedOptions[k].text.length > 4 ? selectedOptions[k].text : selectedOptions[k].id
            let option = lookupOptionById(optionId, opzioniModello)
            //console.log('treeOpzioni  selected option', option)
            if(option) arr.push(option);
        }
        return arr
    }



    const checkRequiresExcludes = function (type, optionId, requiredOptionsArr) {

        /*
        console.log('type > ', type)
        console.log('optionId > ', optionId)
        console.log('requiredOptionsArr > ', requiredOptionsArr)
        */
        requiredOptionsArr.forEach(function (elementToSearch){
            //console.log('checkRequires > ', elementToSearch)
            //console.log('checkRequires selectedOptions > ', selectedOptions)

            if(elementToSearch){

                //messages.push({type : type, who: optionId, what: elementToSearch})

                const index = selectedOptions.findIndex(myOpzione => myOpzione.id === (elementToSearch) || myOpzione.text === (elementToSearch));

                let error = {}
                if(index > -1 && type === 'excludes'){
                    error = {type : type, who: optionId, what: elementToSearch, whatArr: new Array(elementToSearch)}
                    //console.log( 'found option ' + elementToSearch );
                }
                if(index < 0 && type === 'requires'){
                    error = {type : type, who: optionId, what: elementToSearch}
                }

                if(error.who) {
                    error.whoOption = lookupOptionById(optionId, opzioniModello)
                    error.whatOption = lookupOptionById(elementToSearch, opzioniModello)
                    error.whatOptionArr = new Array(lookupOptionById(elementToSearch, opzioniModello))
                    errors.push(error)
                }

            }
        })
    }


    const checkRequires = function (optionId, requiredOptionsArr) {
        //console.log('--- checkRequires ---')
        //console.log('requiredOptionsArr', requiredOptionsArr)

        //console.log('selectedOptions', selectedOptions)

        for (let k = 0; k< requiredOptionsArr.length; k++){
            //console.log('requiredOptionsArr', requiredOptionsArr[k])
            let requiredArr = requiredOptionsArr[k]

            // cerco ogni elemento dell'array requiredArr in selectedOptions
            // devo trovarne almeno uno

            let found = false

            for (let z = 0; z< requiredArr.length; z++){
                let elementToSearch = requiredArr[z]
                //console.log('required',requiredArr[z])
                const index = selectedOptions.findIndex(myOpzione => myOpzione.id === (elementToSearch) || myOpzione.text === (elementToSearch));

                if(index > -1) found = true
            }

            //console.log('required found',found)
            if(!found){

                let error = {}
                // todo requiredArr[0]
                error = {type : 'require', who: optionId, what: requiredArr[0]}

                error.whatArr = requiredArr
                let whatOptionArr = []
                requiredArr.forEach(element =>{
                    whatOptionArr.push(lookupOptionById(element, opzioniModello))
                })

                error.whatOptionArr = whatOptionArr
                error.whoOption = lookupOptionById(optionId, opzioniModello)
                error.whatOption = lookupOptionById(requiredArr[0], opzioniModello)
                errors.push(error)
            }

        }


    }


    /*
    attenzione controlla solo id e il gruppo di option
    se l'opzione requierd è il gruppo del gruppo crea un error
     */
    let checkRequiredOptions = function (requiredOptions) {

        //console.log('< requiredOptions', requiredOptions)
        //console.log('< selectedOptionsInModel', selectedOptionsInModel)

        requiredOptions.forEach(function (myRequiredOption) {



            let requiredOptionsErrors =  selectedOptionsInModel.filter(opzioneModello =>{

                let idsToCompare = [opzioneModello.gruppo, opzioneModello._id]
                //console.log('> opzioneModello gruppo', opzioneModello.gruppo)
                //console.log('> myRequiredOption', myRequiredOption._id)
                return idsToCompare.indexOf( myRequiredOption._id) > -1
                //return (opzioneModello._id === myRequiredOption._id) || (opzioneModello.gruppo === myRequiredOption._id)
            })

            if(requiredOptionsErrors.length < 1){
                //console.log('----------creo errore myRequiredOption', myRequiredOption.nameArr[0].text)

                let error = {
                    type : 'isRequired',
                    who: myRequiredOption._id,
                    whoOption : myRequiredOption,
                    //what: myRequiredOption._id,
                    //whatOption : myRequiredOption
                }

                requiredErrors.push(error)

            }
        })
    }

    let selectedOptionsInModel = getSelectedOptionsInModel()
    //console.log(selectedOptionsInModel)


    for (let k = 0; k< selectedOptionsInModel.length; k++){

        let option = selectedOptionsInModel[k]

        if(option.requires && option.requires.length > 0) {
            //console.log('treeOpzioni  selected option requires', option)
            //checkRequiresExcludes('requires', option._id, option.requires)
            checkRequires(option._id, option.requires)
        }
        if(option.excludes && option.excludes.length > 0) {
            //console.log('treeOpzioni  selected option requires', option)
            checkRequiresExcludes('excludes', option._id, option.excludes)
        }
        //console.log('treeOpzioni  selected option requires', option.requires)
        //console.log('treeOpzioni  selected option excludes', option.excludes)
    }


    //controllo opzioni obbligatorie e creo un error per quelle che mancano in selectedOptionsInModel
    //console.log(opzioniModello, selectedOptionsInModel)
    let requiredOptions = opzioniModello.filter(myOpzione => {return myOpzione.isRequired})
    /*requiredOptions.forEach(function (myRequiredOption) {

        let requiredOptionsErrors =  selectedOptionsInModel.filter(opzioneModello =>{
            return opzioneModello._id === myRequiredOption._id
        })

        console.log('requiredOptionsErrors', requiredOptionsErrors)
    })*/
    checkRequiredOptions(requiredOptions)
    //console.log('selectedOptionsInModel', selectedOptionsInModel)
    //console.log('requiredOptions', requiredOptions)

    //console.log('errors', errors)
    let isValid = errors.length  < 1,
        isValidRequired = requiredErrors.length < 1

    return {isValid: isValid, errors: errors, isValidRequired: isValidRequired, requiredErrors: requiredErrors}
}

const getVincoliOpzioni = function (opzioniModello) {
    let messages = []

    if(!opzioniModello) return messages

    const _checkRequiresExcludes = function (type, optionId, requiredOptionsArr) {
        requiredOptionsArr.forEach(function (elementToSearch){
            //console.log('checkRequires > ', elementToSearch)
            //console.log('checkRequires selectedOptions > ', selectedOptions)

            if(elementToSearch){
                let whoOption = lookupOptionById(optionId, opzioniModello)
                let whatOption = lookupOptionById(elementToSearch, opzioniModello)
                messages.push({type : type, who: optionId, what: elementToSearch, whoOption: whoOption, whatOption: whatOption})
            }
        })
    }


    opzioniModello.forEach(function (option){

        //console.log('getVincoliOpzioni', option)
        if(option.requires && option.requires.length > 0) {
            //console.log('treeOpzioni  selected option requires', option)
            _checkRequiresExcludes('requires', option._id, option.requires)
        }
        if(option.excludes && option.excludes.length > 0) {
            //console.log('treeOpzioni  selected option requires', option)
            _checkRequiresExcludes('excludes', option._id, option.excludes)
        }

    })

    return messages
}

const getOpzioniFixed = function (arr) {
    if(!arr) return arr;

    // elementi root (in nessun gruppo e non fissi )
    let resultArr = arr.filter(function(value, index, arr){

        if(value.isFixed && value.tipo==='opzione'){
            //console.log('index', value)
            //arr.splice(index, 1);
            return value;
        };
        return false
    });
    //console.log('getOpzioniFixed', resultArr)
    return resultArr
}
/**
 * duplica le opzioni dal modello sourceModelKey al modello targetModelKey
 * in ogni opzione ricalcola le id dei campi gruppo, requires e excludes
 * @param sourceModelKey
 * @param targetModelKey
 * @returns {Promise<void>}
 */
const duplicaOpzioniModello = async (sourceModelKey, targetModelKey) => {

    //DEBUG
    //targetModelKey = 'dummy'


    /*

    crea l'array idDaSostituireArr con la mappa dei vecchi id e di quelli nuovi da sostituire {oldId : vecchio_id, newId : nuovo_id}

    per ogni opzione
    cambia modelKey = targetModelKey
    oldId = id opzione originale
    elimina _id, e __v

    aggiunge all'array la mappa  {id vecchio_id : null}

    salva la nuova opzione
    ottiene l'id dell'opzione salvata
    aggiorna la mappa {oldId : id vecchio_id, newId : nuovo_id}

    dopo aver salvato tutte le nuove opzioni
    le carica dal db (con le api)
    e per ognuna sostituisce le id nei campi:
        excludes[]
        requires[]
        gruppo
    */


    const _duplicaOpzione = source => {
        let copia =  {}
        copia = Object.assign(copia, source);
        copia.modelKey = targetModelKey
        copia.oldId = copia._id
        delete copia._id
        delete copia.__v
        return copia
    }

    const _searchId = function (idToSearch, idDaSostituireArr) {
        if(!idToSearch) return idToSearch

        let foundId = idDaSostituireArr.find(function (item) {
            return item.oldId === idToSearch
        })

        if(foundId) {
            //console.log('replace old id',idToSearch)
            //console.log('replace new id',foundId.newId)
            return foundId.newId
        }
        return null

    }

    const _replaceIdInOption = function (option, idDaSostituireArr) {

        if(option.gruppo){
            let foundId = _searchId(option.gruppo, idDaSostituireArr)
            if(foundId) {
                option.gruppo = foundId+''
            }

        }

        if(option.excludes && option.excludes.length > 0 ){

            for(let k = 0; k< option.excludes.length; k++){
                let oldId = option.excludes[k]
                if(oldId){
                    let foundId = _searchId(oldId, idDaSostituireArr)
                    if(foundId){
                        option.excludes[k] = foundId + ''
                    }
                }
            }

            //console.log('\t\toption excludes after',option.excludes)
        }

        //requires
        if(option.requires && option.requires.length > 0 ){

            for(let k = 0; k< option.requires.length; k++){
                let oldId = option.requires[k]
                if(oldId){
                    let foundId = _searchId(oldId, idDaSostituireArr)
                    if(foundId){
                        option.requires[k] = foundId + ''
                    }
                }
            }

            //console.log('\t\toption requires after',option.requires)
        }

        //console.log('option after',option)

        return option

    }

    let oldOptions=[],
        newOptions = [],
        idDaSostituireArr = [];



    await api.getOpzioniByModel(sourceModelKey).then(response => {
        oldOptions = response.data
    })

    //console.log('duplicaOpzioniModello oldOptions', oldOptions);

    oldOptions.forEach(element => newOptions.push(_duplicaOpzione(element)));

    for(let k = 0; k< newOptions.length; k++){

        //console.log('duplicaOpzioniModello save newOptions', newOptions[k]);

        const {  modelKey, tipo, gruppo, rank, nameArr, descriptionArr, noteArr, price, isMultiple, isRequired, isSelectable, requires, excludes, useAccordion, isFixed } = newOptions[k]
        let payload = { modelKey, tipo, gruppo, rank, nameArr, descriptionArr, noteArr, price, isMultiple, isRequired, isSelectable, requires, excludes, useAccordion, isFixed }

        //console.log('duplicaOpzioniModello save payload', payload);
        //console.log('duplicaOpzioniModello  newOptions[k].oldId', newOptions[k].oldId);

        let currentOption = newOptions[k]

        // salvo la nuova opzione i aggingo il vecchio id e quello nuovo in idDaSostituireArr
        await api.insertOpzione(payload).then(res => {
            idDaSostituireArr.push({'oldId': currentOption.oldId, 'newId': res.data._id})
        })

    }

    //console.log('duplicaOpzioniModello  idDaSostituireArr', idDaSostituireArr);

    // ricarico le nuovo opzioni salvate
    await api.getOpzioniByModel(targetModelKey).then(response => {
        newOptions = response.data
    })


    // aggiorna i campi gurppo requires e excludes e salva la option
    newOptions.forEach(async element => {

        //console.log('option before',element)
        _replaceIdInOption(element, idDaSostituireArr)
        //console.log('before save option', element)


        const {  modelKey, tipo, gruppo, rank, nameArr, descriptionArr, noteArr, price, isMultiple, isRequired, isSelectable, requires, excludes, useAccordion, isFixed } = element
        let payload = { modelKey, tipo, gruppo, rank, nameArr, descriptionArr, noteArr, price, isMultiple, isRequired, isSelectable, requires, excludes, useAccordion, isFixed }

        await api.updateOpzioneById(element._id, payload).then(res => {
            //window.alert(`Movie updated successfully`)
            console.log(res)

        })

    });





}


const getOpzioniSelezionate = function (opzioniModello, opzioniSelezionate, t, i18n) {

    let selectedOptions = []

    let getSelectedChildren = (parentItem) => {


        let children = []

        if(!parentItem.children || parentItem.children.length < 1) return children

        parentItem.children.forEach((item, index)=> {


            let checkId = (selected) => { return selected.id ===item._id || selected.text ===item._id || !item.isSelectable}

            // opzioni radio
            // parentItem.tipo === gruppo && parentItem.isMultiple === false
            if(parentItem.tipo === 'gruppo' && parentItem.isMultiple === false && item.isSelectable === true){
                checkId = (selected) => { return selected.id ===parentItem || selected.text ===item._id }
            }


            if(opzioniSelezionate && opzioniSelezionate.find(checkId)){
                //console.log('-----child', item._id)

                //console.log('-----child', item._id + ' ' + label)
                item.tLabel = getTextInLangArr(item.nameArr, i18n.language)

                //console.log('-----found child', item._id + ' ' + item.tLabel)

                children.push(item)
            }


            let _children = getSelectedChildren(item)
            if(_children.length > 0) {
                item.selectedChildren = _children
                //console.log('_children', _children.length)
            }
        })

        return children

    } // end getSelectedChildren


    opzioniModello.forEach((item, index)=> {
        //console.log(item)
        item.tLabel = treeOpzioni.getTextInLangArr(item.nameArr, i18n.language)



        // TODO
        // gestire anche opzioni oltre ai gruppi



        let isSelected = false

        // se opzione selezionata
        if(opzioniSelezionate && opzioniSelezionate.find(myOpzione => (myOpzione.id ===item._id) || myOpzione.text ===item._id)){
            isSelected = true
        }

        if(isSelected  || !item.isSelectable){
            //console.log('------------------\ncerco opzione/gruppo ', item.tLabel)
            let children = getSelectedChildren(item)


            if(children.length > 0 ) {
                item.selectedChildren = children
                //console.log('parent',  label + ' ' + children.length)
                selectedOptions.push(item)
            }
        }


    })

    return selectedOptions


} // end getOpzioniSelezionate

/**
 * trasforma l'array delle opzioni
 * sposta i sotto gruppi nel proprio gruppo
 * per prima cosa cerca i gruppi e le opzioni principali (campo gruppo null o vuoto)
 *
 * @param arr
 */
const creaStrutturaOpzioni = function (arr){

    if(!arr) return arr;
    //console.log('before arr',arr.length);
    // gruppi e opzioni principale

    const isNotInAnyGruppo = (element) => (element.gruppo === undefined || element.gruppo === null || element.gruppo.trim().length < 1)

    const isInGruppo = function (element, nomeGruppo) {
        return (element.gruppo !== undefined && element.gruppo !== null && element.gruppo.trim() === nomeGruppo)
    }

    function compareByRank( a, b ) {

        if ( a.rank < b.rank ){
            return -1;
        }
        if ( a.rank > b.rank ){
            return 1;
        }
        return 0;
    }

    /**
     * attenzione, questa funzione richiama se' stessa per processare i gruppi
     * richiede la var arr (array di tutte le opzioni)
     * @param nomeGruppo
     * @returns []
     */
    const addChildrenToGroup = function(nomeGruppo){

        let childrenArr = arr.filter(function(value, index){
            //console.log('check is in gruppo index', nomeGruppo)
            if(isInGruppo(value, nomeGruppo)){
                //console.log('is in sub gruppo index', index)
                //arr.splice(index, 1);
                return true;
            };
            return false
        });

        childrenArr.sort(compareByRank)

        childrenArr.forEach(function (elem) {
            //console.log('\tchild', elem.rank + ' ' + elem.nameArr[0].text)
            if(elem.tipo === "gruppo"){
                //console.log('\tsub gruppo ', elem.nameArr[0].text + ' ' + elem._id)
                elem.children = addChildrenToGroup(elem._id);
            }
        })

        return childrenArr;

    }


    // elementi root (in nessun gruppo)
    let rootArr = arr.filter(function(value, index, arr){

        if(isNotInAnyGruppo(value)){
            //console.log('index', value)
            //arr.splice(index, 1);
            return value;
        };
        return false
    });

    //let rootArr = arr.filter(isNotInAnyGruppo)


    //console.log('rootArr len',rootArr.length);
    //console.log('after arr',arr.length);

    rootArr.sort(compareByRank)

    for (let k = 0; k < rootArr.length; k++){
        let element = rootArr[k]
        //console.log('> root', element.rank + ' ' + element.nameArr[0].text + ' '  + element._id);
        if(element.tipo && element.tipo === 'gruppo' ){
            element.children = addChildrenToGroup(element._id, arr);
        }
    }

    //console.log('rootArr',rootArr);
    //console.log('after arr',arr);

    return rootArr;

}

const calcolaPrezzo = function (arr, selectedOptions, customOptions){

    let price = 0;
    //console.log('treeOpzioni calcolaPrezzo arr', arr)
    //console.log('treeOpzioni calcolaPrezzo selectedOptions', selectedOptions)
    //console.log('treeOpzioni calcolaPrezzo customOptions', customOptions)

    arr.forEach(function (elem) {

        if(elem.price){

            if(!elem.isSelectable  ){
                //console.log(elem.price);
                price += elem.price
            }

            // cerca opzione in selectedOptions
            const index = selectedOptions.findIndex(myOpzione => myOpzione.id === (elem._id) || myOpzione.text === (elem._id));
            if(index > -1){
                //console.log( elem.price, elem.nameArr[0].text);
                price += elem.price
            }
        }
    })

    customOptions && customOptions.forEach(function (elem) {
        //console.log( elem.text, elem.price);
        if(!isNaN(elem.price)) {

            price += elem.price
        }
    })


    return Math.round(price * 100) / 100

}

const getTextInLangArr= function(arr, langKey) {
    if(langKey) langKey = langKey.toUpperCase()
    //console.log( arr, langKey);
    let result = arr && arr.length > 0 ?  arr[0].text : '';
    if(!arr || !langKey) return result;

    for(let i = 0; i < arr.length; i++){
        if(arr[i].lang === langKey) return arr[i].text;
    }

    return result;
}


function compareOptionsByLangText( a, b ) {
    let langKey = 'IT'

    let text_a = getTextInLangArr(a.nameArr, langKey),
         text_b = getTextInLangArr(b.nameArr, langKey)

    if(text_a < text_b) { return -1; }
    if(text_a > text_b) { return 1; }
    return 0
}



const treeOpzioni = {
    creaStrutturaOpzioni,
    lookupOptionById,
    calcolaPrezzo,
    convalidaPreventivo,
    getVincoliOpzioni,
    getOpzioniFixed,
    getTextInLangArr,
    duplicaOpzioniModello,
    getOpzioniSelezionate,
    compareOptionsByLangText,
}

export default treeOpzioni