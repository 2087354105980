import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import { Page, Text, View, Document, Image, StyleSheet, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import api from '../api'
import treeOpzioni from '../utilities/treeOpzioni'
import FormatCurrency from '../utilities/FormatCurrency'
import FormatDate from '../utilities/FormatDate'
import { withTranslation } from 'react-i18next'
const queryString = require('query-string');


/*
url fonts google
esempio curl https://fonts.googleapis.com/css?family=Work+Sans:700

 */

//  src : 'https://fonts.gstatic.com/s/worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBi8Jow.ttf',


Font.register({family: 'WorkSans', fontStyle: 'normal',  fonts: [
        { src: 'https://fonts.gstatic.com/s/worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBi8Jow.ttf', fontWeight: 'normal'},
        { src: 'https://fonts.gstatic.com/s/worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QBi8Jow.ttf', fontWeight: 'bold' },
    ]
});

// Create styles
const styles = StyleSheet.create({
    page: {
        fontSize: '9pt',
        paddingTop: 35,
        paddingBottom: 120,
        paddingHorizontal: 35,
        fontFamily: 'WorkSans',
    },
    section: {
        padding: 10,
        border:'0pt solid #000000',
    },
    logo: {
        width: '55pt',
        height:'55pt'
    },
    logoFooter: {
        height: '45pt',

    },
    h1: {
        fontSize : '18pt',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    h3: {
        fontSize : '12pt',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    gruppoRow: {
        marginTop: '3pt',
        paddingTop: '5pt',
        borderTop: '0.5pt solid #000000',
    },
    opzioneRow: {
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    gruppo: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    subGruppo: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color:'#666666'
    },
    opzione: {
        fontWeight: 'normal',
    },
    opzioneLabel: {
        width: '80%',
        paddingBottom: '3pt'
    },
    opzionePrice: {
        width: '20%',
        paddingBottom: '3pt',
        textAlign: 'right',
    },
    borderTopBottom: {
        borderTop:'0.5pt solid #666666',
        borderBottom:'0.5pt solid #666666',
        paddingTop: '3pt',
    },
    header: {
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '30pt',
        borderBottom: '0 solid #000000'
    },
    sezioneFirma:{
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '20pt',
        marginBottom: '20pt',
        padding: 10
    },
    sezioneFirmaCol:{
        width: '30%',
        paddingVertical:'2pt',
        borderBottom:'0.5pt solid black',
        textTransform:'capitalize'
    },
    footer:{
        position: 'absolute',
        bottom: '20pt',
        left: '35pt',
        height: '65pt',
        width: '100%',
        border:'0pt solid #FF0000',
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '7pt',
        color: '#666666',
        paddingTop: '10pt'
    },
    footerCol:{
        width: '20%',
        border:'0pt dotted #FF0000',
    }
});

class Opzione extends Component {


    render(){

        //let className = this.props.element.tipo === 'gruppo' ? styles.gruppo : styles.opzione
        let className =  styles.opzione

        if(this.props.element.tipo === 'gruppo'){
            className = styles.gruppo
            if(this.props.subGruppo)  className = styles.subGruppo
        }


        return(

            <View style={[styles.opzioneRow, this.props.element.tipo === 'gruppo' ? styles.gruppoRow : '']}>
                <Text style={[className, styles.opzioneLabel]}>{this.props.element.tLabel}</Text>
                <Text style={[styles.opzionePrice]}><FormatCurrency value={this.props.element.price}/></Text>
            </View>

        )
    }
}

class CondizioniCommerciali extends Component{


    render(){
        return(


            <View style={styles.section} wrap={false}>
                <View style={styles.opzioneRow}>
                    <Text style={[styles.gruppo, styles.opzioneLabel]}>{this.props.t('condizioniCommerciali')}</Text>
                    <Text style={ styles.opzionePrice}></Text>


                    {(this.props.data.discount)? (
                        <>
                            <Text style={[styles.opzione, styles.opzioneLabel]}>{this.props.t('discount')}</Text>
                            <Text style={ styles.opzionePrice}>-<FormatCurrency value={this.props.data.discount}/></Text>
                        </>

                    ):(<></>)}

                    {(this.props.data.freightCharges)? (
                        <>
                            <Text style={[styles.opzione, styles.opzioneLabel]}>{this.props.t('freightCharges')}</Text>
                            <Text style={ styles.opzionePrice}><FormatCurrency value={this.props.data.freightCharges}/></Text>
                        </>

                    ):(<></>)}

                    {(this.props.data.tradeInValue)? (
                        <>
                            <Text style={[styles.opzione, styles.opzioneLabel]}>{this.props.t('tradeInValue')}</Text>
                            <Text style={ styles.opzionePrice}>-<FormatCurrency value={this.props.data.tradeInValue}/></Text>
                        </>

                    ):(<></>)}

                    {(this.props.data.tradeInDescription)? (
                        <>
                            <Text style={[styles.opzione, styles.opzioneLabel]}>{this.props.t('tradeInDescription')}</Text>
                            <Text style={ styles.opzionePrice}>{this.props.data.tradeInDescription}</Text>
                        </>

                    ):(<></>)}


                </View>
            </View>

        )
    }
}

class CondizioniDiVentita extends Component{

    render(){

        return(
            <View style={styles.section} wrap={false}>
                <View style={styles.opzioneRow}>

                    <Text style={[styles.gruppo, styles.opzioneLabel]}>{this.props.t('condizioniVendita')}</Text>
                    <Text style={ styles.opzionePrice}></Text>


                    {(this.props.data.validity)? (
                        <>
                        <Text style={[styles.opzione, styles.opzioneLabel]}>{this.props.t('validity')}</Text>
                        <Text style={ styles.opzionePrice}>{this.props.data.validity}</Text>
                        </>

                    ):(<></>)}


                    {(this.props.data.delivery)? (
                        <>
                            <Text style={[styles.opzione, styles.opzioneLabel]}>{this.props.t('delivery')}</Text>
                            <Text style={ styles.opzionePrice}>{this.props.data.delivery}</Text>
                        </>

                    ):(<></>)}


                    {(this.props.data.paymentTerms)? (
                        <>
                            <Text style={[styles.opzione, styles.opzioneLabel]}>{this.props.t('paymentTerms')}</Text>
                            <Text style={ styles.opzionePrice}>{this.props.data.paymentTerms}</Text>
                        </>

                    ):(<></>)}


                </View>
            </View>
        )
    }
}

class Gruppo extends Component {


    render(){



        return(

            <View style={{ display: "block",}} wrap={true}>


                {this.props.element.tipo !== 'gruppo' && (
                    <Opzione element={this.props.element} subGruppo={this.props.subGruppo} />
                )}


                {this.props.element.tipo === 'gruppo' && (

                    <View stye={{border:'1pt solid #FF0000'}} wrap={false}>
                        <Opzione element={this.props.element} subGruppo={this.props.subGruppo}/>

                        {this.props.element.selectedChildren && this.props.element.selectedChildren.map((item, index)=> {

                            let sub =   <Opzione key={index} element={item}/>
                            if(item.tipo === 'gruppo') sub =  <Gruppo key={index} element={item} subGruppo={true} />

                            return (
                                <div key={index}>{sub}</div>
                            )
                        })
                        }
                    </View>
                )}

            </View>

        )
    }
}

class Pdf extends Component {

    render() {

        //console.log(this.props)

        const { t, i18n } = this.props;
        //let history = useHistory();


        return(
            <>
                {this.props.data &&
                <Document>
                    <Page size="A4" style={styles.page}>


                        <View style={styles.header}  fixed>

                            <Image
                                style={styles.logo}
                                src="/logo-pdf.jpg"
                            />
                            <Text style={{width: '50%', textTransform: 'uppercase', textAlign: 'right'}}>Sacsmarine.com</Text>
                        </View>

                        <View style={[styles.section, {marginTop:'50pt'}]}>
                            <Text style={[styles.h1, {color: '#666666'}]}>{t('preventivo')}</Text>
                            <Text style={styles.h1}>{treeOpzioni.getTextInLangArr(this.props.data.model.nameArr, i18n.language)}</Text>
                            <Text style={{paddingVertical:'2pt'}}><FormatDate value={this.props.data.updated_at}/></Text>
                            <Text style={{paddingVertical:'2pt'}}>{this.props.data.name}</Text>
                        </View>
                        <View style={[styles.section, {marginTop:'30pt', marginBottom:'30pt'}]}>
                            <Text style={styles.h3}>{this.props.data.customerFirstName} {this.props.data.customerLastName}</Text>
                            <Text style={{paddingVertical:'2pt'}}>{this.props.data.customerEmail}</Text>
                            <Text style={{paddingVertical:'2pt'}}>{this.props.data.customerPhone}</Text>
                            <Text style={{paddingVertical:'2pt'}}>{this.props.data.customerAddress}</Text>
                        </View>


                        <View style={styles.section}>
                            {this.props.data.selectedOptions.map((item, index)=> {
                                return (<View key={index}   >

                                    <Gruppo element={item} subGruppo={false}/>

                                </View>)
                            })
                            }

                            {this.props.data.customOptions.map((item, index    )=> {
                                return(
                                    <View key={index} style={styles.opzioneRow}>
                                        <Text style={[styles.opzione, styles.opzioneLabel]}>{item.text}</Text>
                                        <Text style={ styles.opzionePrice}><FormatCurrency value={item.price}/></Text>
                                    </View>
                                )
                            })}
                        </View>

                        <View wrap={false}>

                            <View style={styles.section} wrap={false}>
                                <View style={[styles.opzioneRow, styles.borderTopBottom]}>
                                    <Text style={[styles.gruppo, styles.opzioneLabel]}>{t('prezzoTotale')}</Text>
                                    <Text style={ styles.opzionePrice}><FormatCurrency value={this.props.data.price}/></Text>
                                </View>
                            </View>

                            {(this.props.data.discount || this.props.data.freightCharges || this.props.data.tradeInValue)? (
                                <CondizioniCommerciali t={t} data={this.props.data} />
                            ):(<></>)}



                            <View style={styles.section} wrap={false}>
                                <View style={[styles.opzioneRow, styles.borderTopBottom]}>
                                    <Text style={[styles.gruppo, styles.opzioneLabel]}>{t('prezzoNetto')}</Text>
                                    <Text style={ [styles.opzionePrice, {fontWeight:'bold'}]}><FormatCurrency value={this.props.data.prezzoNetto}/></Text>
                                </View>
                            </View>


                            {(this.props.data.validity || this.props.data.delivery || this.props.data.paymentTerms)? (
                                    <CondizioniDiVentita t={t} data={this.props.data} />
                            ):(<></>)}




                            <View style={styles.section}>

                                {this.props.data.opzioniFixed.map((item, index)=> {
                                    return (
                                        <View key={index}>
                                            <Text style={{paddingBottom: '3pt'}}>{item.tLabel}</Text>
                                        </View>
                                    )
                                })
                                }
                            </View>

                            <View style={styles.sezioneFirma}>

                                <Text style={styles.sezioneFirmaCol}>{this.props.t('data')}:</Text>
                                <Text style={styles.sezioneFirmaCol}>{this.props.t('luogo')}:</Text>
                                <Text style={styles.sezioneFirmaCol}>{this.props.t('firma')}:</Text>

                            </View>

                        </View>


                        <View style={styles.footer} fixed>



                            <Image
                                style={styles.logoFooter}
                                src="/logo2-pdf.jpg"
                            />

                            <Text style={styles.footerCol}>
                                <Text style={{ fontWeight: 'bold', textTransform: 'uppercase', }}>{this.props.datiFooter.companyName ? this.props.datiFooter.companyName : ''}{"\n"}</Text>
                                {this.props.datiFooter.address ? this.props.datiFooter.address : ''}{"\n"}
                                {this.props.datiFooter.zipCode ? this.props.datiFooter.zipCode : ''} {this.props.datiFooter.city ? this.props.datiFooter.city : ''}{"\n"}
                                {this.props.datiFooter.country ? this.props.datiFooter.country : ''}
                            </Text>

                            <Text style={styles.footerCol}>
                                <Text style={{ fontWeight: 'bold', }}>{this.props.datiFooter.email2 ? 'M' : ''}</Text> {this.props.datiFooter.email2 ? this.props.datiFooter.email2 : ''}{"\n"}
                                <Text style={{ fontWeight: 'bold', }}>{this.props.datiFooter.phone1 ? 'Ph' : ''}</Text> {this.props.datiFooter.phoneAreaCode1 ? this.props.datiFooter.phoneAreaCode1 : ''} {this.props.datiFooter.phone1 ? this.props.datiFooter.phone1 : ''}{"\n"}
                                <Text style={{ fontWeight: 'bold', }}>{this.props.datiFooter.phone2 ? 'F' : ''}</Text> {this.props.datiFooter.phoneAreaCode2 ? this.props.datiFooter.phoneAreaCode2 : ''} {this.props.datiFooter.phone2 ? this.props.datiFooter.phone2 : ''}

                            </Text>


                            <Text style={[styles.footerCol, { textAlign: 'right'}]} render={({ pageNumber, totalPages }) => (
                                `${pageNumber} / ${totalPages}\n\n${this.props.data._id}`
                            )} />


                        </View>


                    </Page>
                </Document>
                }

            </>)
    }
}

class PdfDocument extends Component {
    constructor(props) {
        super(props)

        this.state = {
            preventivoData: null,
            datiFooter:{},
            isLoading: false,
            pdfFileName: "quotation"
        }
    }


    componentDidMount = async () => {

        this.setState({ isLoading: true })

        //let id = this.props.id


        const parsed = queryString.parse(window.location.search);

        let id = parsed.id


        //console.log('PDF componentDidMount', this.props)
        //console.log('PDF componentDidMount', id)

        let preventivoData,
            treeOpzioniModello,
            datiFooter = {},
            pdfFileName = "quotation"

        await api.getPreventivoById(id).then(preventivo => {
            //preventivo = preventivo.data
            preventivoData = preventivo.data
            pdfFileName += "_" + preventivoData.modelKey
            //console.log('preventivo', preventivo)
        })



        let model
        await api.getModelByKey(preventivoData.modelKey).then(data => model = data)

        preventivoData.model = model

        await api.getOpzioniByModel(preventivoData.modelKey).then(opzioniModello => {
            //console.log(opzioniModello.data);

            treeOpzioniModello = treeOpzioni.creaStrutturaOpzioni(opzioniModello.data);
            preventivoData.opzioniFixed = treeOpzioni.getOpzioniFixed(opzioniModello.data)


        })

        //console.log('PDF componentDidMount preventivoData', preventivoData)

        preventivoData.selectedOptions = treeOpzioni.getOpzioniSelezionate(treeOpzioniModello, preventivoData.opzioniArr, this.props.t, this.props.i18n)
        preventivoData.prezzoNetto = preventivoData.price - preventivoData.discount + preventivoData.freightCharges - preventivoData.tradeInValue

        let dealer = await api.getUserByUsername(preventivoData.userId)
        if(dealer)  dealer=dealer.data
        if(dealer && dealer.companyName && dealer.address && dealer.country) {

            // dati footer con dati del dealer

            datiFooter.companyName = dealer.companyName ? dealer.companyName : null
            datiFooter.address = dealer.address ? dealer.address : null
            datiFooter.zipCode = dealer.zipCode ? dealer.zipCode : null
            datiFooter.city = dealer.city ? dealer.city : null
            datiFooter.country = dealer.country ? dealer.country : null
            datiFooter.phoneAreaCode1 = dealer.phoneAreaCode1 ? dealer.phoneAreaCode1 : null
            datiFooter.phone1 = dealer.phone1 ? dealer.phone1 : null
            datiFooter.phoneAreaCode2 = dealer.phoneAreaCode2 ? dealer.phoneAreaCode2 : null
            datiFooter.phone2 = dealer.phone2 ? dealer.phone2 : null
            datiFooter.email2 = dealer.email2 ? dealer.email2 : null

        }else{
            // dati footer default (dealer not found)

            datiFooter.companyName =" Sacs Tecnorib S.p.a"
            datiFooter.address="Via Don G. Locatelli, 51"
            datiFooter.zipCode="20877"
            datiFooter.city=" Roncello (MB)"
            datiFooter.country="Italy"
            datiFooter.phoneAreaCode1="+39"
            datiFooter.phone1="0396885489"
            datiFooter.phoneAreaCode2="+39"
            datiFooter.phone2="0396956234"
            datiFooter.email2="info@sacstecnorib.com"
        }

        //console.log('dealer data', dealer)


        this.setState({
            isLoading : false,
            preventivoData : preventivoData,
            datiFooter: datiFooter,
            pdfFileName: pdfFileName
        });

    }

    render() {

        const { t, i18n } = this.props;
        //console.log('t', t)
        return(

            <>
                <div style={{textAlign:'center', marginBottom:'15px'}}>
                    <Button href={'/preventivi/list'} variant="secondary" style={{textDecoration:'none'}}>{'< ' + t('indietro')}</Button>{' '}
                    {this.state.preventivoData && <Button href={'/preventivo/?id=' + this.state.preventivoData._id} variant="secondary" style={{textDecoration:'none'}}>{t('preventivo')}</Button>}
                </div>

                {(!this.state.isLoading && this.state.preventivoData) &&

                <><div style={{textAlign:'center', marginBottom:'15px'}}>
                    <Button variant="secondary"><PDFDownloadLink document={<Pdf data={this.state.preventivoData} datiFooter={this.state.datiFooter}  t={t} i18n={i18n}  />} className="pdf-download-link" fileName={this.state.pdfFileName + ".pdf"}>
                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
                    </PDFDownloadLink></Button></div>

                <PDFViewer width={'100%'} style={{height:'80vh'}}>
                    <Pdf data={this.state.preventivoData} datiFooter={this.state.datiFooter}  t={t} i18n={i18n}  />
                </PDFViewer>
                </>}
            </>)
    }
}

export default withTranslation('translations')(PdfDocument)