//import React from 'react'
import styled from 'styled-components'


const Wrapper = styled.div.attrs({
    className: 'wrapper',
})``


const CancelButton = styled.a.attrs({
    className: `btn btn-secondary`,
})`
    margin: 15px 15px 15px 5px;
`

const commonComponents = {
    Wrapper,
    CancelButton,
}


export default commonComponents