import React, { Component } from 'react'
import api from '../api'
import { useAuth } from "../context/auth";
import Button from 'react-bootstrap/Button'
import { withTranslation } from 'react-i18next'


class CreateUtente extends Component {



    createUtente = event => {
        event.preventDefault()
        window.location.href = `/users/update/new`
    }

    render() {
        return <Button onClick={this.createUtente}>{this.props.t('nuovo')} {this.props.t('utente')}</Button>
    }
}

class UsersList extends Component {

    constructor(props) {
        super(props)


        this.state = {
            usersList: []
        }
    }


    componentDidMount = async () => {
        this.setState({isLoading: true})




        await api.getAllUsers().then(usersList => {
            //console.log(usersList.data);
            this.setState({
                usersList: usersList.data,
            })
        })
    }


    render() {

        const { t } = this.props;
        return (

            <>

                <div className="row mb-1">
                    <div className="col-6"><h1>{t('utenti')}</h1></div>

                    <div className="col-6 text-right">
                        <CreateUtente t={t}></CreateUtente>


                    </div>
                </div>



                <div className="row border-top text-uppercase">
                    <div className="col p-2"><h4>email</h4></div>
                    <div className="col p-2"><h4>role</h4></div>
                    <div className="col p-2"><h4>status</h4></div>
                    <div className="col p-2"><h4>{t('crmUserId')}</h4></div>
            </div>

                {1>0 && (this.state.usersList.map((item, index)=> {

                            return(

                                <div className="row border-top" key={index}>
                                    <div className="col p-2">
                                        <strong>{item.email}</strong>
                                        <br /><small>{item.customData}</small>
                                        <br /><a href={'/users/update/' + item._id}>{t('modifica')}</a>
                                    </div>
                                    <div className="col p-2">
                                        {(item.isAdmin ? 'admin': '-')}
                                    </div>
                                    <div className="col p-2">
                                        {(item.isActive ? 'ON': 'disabled')}
                                    </div>
                                    <div className="col p-2">
                                        {(item.crmUserId ? item.crmUserId: '-')}
                                    </div>
                                </div>
                            )

                        }
                    )
                )
                }

            </>
        )
    }
}

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const auth = useAuth();
        //console.log('withMyHook authTokens', auth)
        return <Component {...props} authTokens={auth.authTokens} />;
    }
}

export default withTranslation('translations')(withMyHook(UsersList))