import styled from 'styled-components';

const Card = styled.div`
  box-sizing: border-box;
  max-width: 410px;
  margin: 5rem auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 1rem;
  border: 1px solid #e0e1e2;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: #15202c;
  border-color: #15202c;
  padding: 1rem;
  color: white;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1rem;
  text-transform: uppercase;
`;

const Logo = styled.img`
  width: 50%;
  margin-bottom: 1rem;
`;

const Error = styled.div`
  color: red;
`;

export { Form, Input, Button, Logo, Card, Error };