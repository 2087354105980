import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { NavBar } from '../components'
import { ModelsList, OpzionesList, OpzioneUpdate, PreventivoUpdate, PreventiviList, UsersList, UserUpdate, Login, Signup, Pdf } from '../pages'

import PrivateRoute from '../PrivateRoute';

import { AuthContext } from "../context/auth";

import {I18nextProvider} from 'react-i18next';
import i18n from '../locales/i18n';
import CommonComponents from '../components/CommonComponents'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../style/custom.css'


window.languageArr = ['IT',"EN","FR"]

function App(props) {


    /*
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    */

    let localStorageTokens = localStorage.getItem("tokens")
    //console.log('index.js before JSON.parse', localStorageTokens)
    let existingTokens //= localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")) : null;

    // non si capisce perchè localStorageTokens === undefined non funziona
    // (forse è il valore di localStorage tokens che è undefined)
    // l'unico sistema è il try catch
    // ** da sistemare **
    try{
        existingTokens = JSON.parse(localStorageTokens)
        //console.log(existingTokens.langCode)
        //changeLanguage(existingTokens.langCode)
    }catch (e) {

    }


    const [authTokens, setAuthTokens] = useState(existingTokens);

    const setTokens = (data) => {
        localStorage.setItem("tokens", JSON.stringify(data));
        setAuthTokens(data);
    }

    return (
        <I18nextProvider i18n={ i18n }>
            <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
                <Router>
                    <NavBar authTokens={authTokens}/>
                    <CommonComponents.Wrapper>
                        <div className="container my-5">


                    <Switch>
                        <Route path="/pdf" component={Pdf} />
                        <Route path="/login" component={Login} />
                        <Route path="/signup" component={Signup} />
                        <PrivateRoute path="/users/list" component={UsersList} />
                        <PrivateRoute
                            path="/users/update/:id"
                            exact
                            component={UserUpdate}
                        />
                        <PrivateRoute path="/models/list" exact component={ModelsList} />
                        <PrivateRoute path="/options/list/:id" exact component={OpzionesList} />
                        <PrivateRoute
                            path="/options/update/:id"
                            exact
                            component={OpzioneUpdate}
                        />
                        <PrivateRoute path="/" exact component={PreventiviList} />
                        <PrivateRoute path="/preventivi/list" exact component={PreventiviList} />
                        <PrivateRoute path="/preventivo/" exact component={PreventivoUpdate} />

                    </Switch>

                        </div>
                    </CommonComponents.Wrapper>
                </Router>
            </AuthContext.Provider>
        </I18nextProvider>
    )
}

export default App