import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
//import NavDropdown from 'react-bootstrap/NavDropdown'
//import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import Logo from './Logo'
import Links from './Links'
import Logout from './Logout'

//import api from '../api'
/*
const Container = styled.div.attrs({
    className: 'container-fluid',
})``

const Nav = styled.nav.attrs({
    className: 'navbar navbar-expand-lg navbar-dark bg-dark',
})`
    margin-bottom: 20 px;
`
*/
class NavBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            navExpanded : false
        }

        this.setNavExpanded = this.setNavExpanded.bind(this);
        this.closeNav = this.closeNav.bind(this)
    }

    setNavExpanded(expanded) {
        this.setState({ navExpanded: expanded });
    }

    closeNav() {
        this.setState({ navExpanded: false });
    }


    render() {

        const { i18n } = this.props;
        const changeLanguage = (lng) => {
            //console.log('changeLanguage', lng)
            i18n.changeLanguage(lng);
        };

        let UsernameText = <></>
        let logOut = <></>


        if(this.props.authTokens ){

            UsernameText=  <Navbar.Text>
                {this.props.authTokens.email}
            </Navbar.Text>

            logOut = <Logout/>

        }

        return (

                <Navbar bg="dark" variant="dark" expand="lg" onToggle={this.setNavExpanded} expanded={this.state.navExpanded} >
                    <Logo />
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Links authTokens={this.props.authTokens} onSelect={this.closeNav} />

                        </Nav>

                        <Nav>
                            {UsernameText}
                            {logOut}
                            <Nav.Link eventKey="en" onClick={this.closeNav} onSelect={() => changeLanguage('en')}>EN</Nav.Link>
                            <Nav.Link eventKey="it" onClick={this.closeNav} onSelect={() => changeLanguage('it')}>IT</Nav.Link>
                        </Nav>

                    </Navbar.Collapse>
                </Navbar>

        )
    }
}

export default withTranslation('translations')(NavBar)
