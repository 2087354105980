import React, { Component } from 'react'
import NumberFormat from 'react-number-format'

class FormatCurrency extends Component {

    render() {
        return (
            <NumberFormat value={this.props.value} displayType={'text'} thousandSeparator={'.'} decimalSeparator="," decimalScale="2" prefix={'€'} />)
    }
}

export default FormatCurrency