import NumberFormat from 'react-number-format';
import Moment from 'moment';

const formatMoney = function (arg, currency){

    if(arg === undefined || arg == null) arg = 0;
    if(currency === undefined || currency == null) currency = '';

    /*
    let format = {
    thousandSeparator:'.',
    decimalSeparator: ',',
    suffix:'euro',
    }
    */

    var myFormat = NumberFormat({suffix: currency,  integerSeparator : ".", decimal : ",", padRight : 2});
    var formattedNumber = myFormat(arg,  {noSeparator: false});
    return formattedNumber;

};


const randomString = function(sLen) {
    //console.log('findAll', jsonSort);
    if(!sLen) sLen = 32;
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for( var i=0; i < sLen; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
};

const formatDate = function(date, format) {
    //console.log('findAll', jsonSort);
    Moment.locale('it');
    if(!format) format = "DD/MM/yyyy"

    return Moment(date).format(format)


};

const utilities = {
    formatMoney,
    randomString,
    formatDate
}

export default utilities