import React, { Component } from 'react'
import FieldOpzione from './FieldOpzione'
import ConfigurationErrorMessage from '../components/ConfigurationErrorMessage'
import { withTranslation } from 'react-i18next'
import treeOpzioni from "../utilities/treeOpzioni";


const opzioneHasErrors = function (opzione, errors) {

    let hasError = false;

    errors.forEach(error=>{
        //console.log('opzioneHasErrors', error)
        let idsToCompare = [error.who, error.what]
        if( idsToCompare.indexOf(opzione._id) > -1) hasError = true
    })

    return hasError

}



class OpzionePreventivo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            element : this.props.element,
            selectedElements : this.props.selectedElements,
            isOpen : false
        }
        //const [open, setOpen] = useState(false);
    }

    setOpen = function () {
        let open = this.state.isOpen ? false : 'open'
        //console.log('setOpen', open)
        this.setState({isOpen : open})
    }

    /**
     * cerca il componente (itemId) in convalidaPreventivo.errors per stabilire se e da quale/i opzione/i è richiesto
     * restituisce un array con le opzioni che richiedono itemId
     * @param convalidaPreventivo
     * @param itemId
     * @returns [option]
     */
    findOptionInConvalidaPreventivoErrors = function(convalidaPreventivo, itemId){

        let results = []

        if(!this.props.convalidaPreventivo ||
            !this.props.convalidaPreventivo.errors ||
            !this.props.convalidaPreventivo.errors ||
            !this.props.convalidaPreventivo.errors){

            return results

        }
        this.props.convalidaPreventivo.errors.forEach(errorItem=>{

            if(errorItem.whatArr.indexOf(itemId) > -1){
                //console.log(itemId, errorItem)
                if(errorItem.type === "require") results.push(errorItem.whoOption)
            }
        })

        return results
    }




    render() {
        let className = 'option',
            containerClassName = '',
            selectedClassName = '',
            disabledClassName = ''

        const { t } = this.props;
        //const { preventivi } = this.state


        //console.log('this.props.selectedElements', this.props.selectedElements)
        //console.log('this.props.useAccordion', this.state.useAccordion)

        if (this.props.element.tipo === 'gruppo') {
            className = 'gruppo';
            containerClassName = 'group-container'
        }

        if(this.props.selectedElements && this.props.selectedElements.includes(this.props.element._id)){
            selectedClassName = ' selected'
        }

        if(!this.props.element.isSelectable && this.props.filterSelectable) disabledClassName = ' disabled'

        // controllo se gli errori fanno riferimento all'opzione

        let hasErrors = opzioneHasErrors(this.props.element, this.props.convalidaPreventivo.errors)
        //console.log('convalidaPreventivo', this.props.convalidaPreventivo)ù

       // let isRequiredBy = this.props.convalidaPreventivo && this.props.convalidaPreventivo.errors && this.props.convalidaPreventivo.errors && this.props.convalidaPreventivo.errors.whatArr ? true : false
       // if(isRequiredBy  ) console.log('---isRequiredBy----')

        let isRequiredBy = this.findOptionInConvalidaPreventivoErrors(this.props.convalidaPreventivo, this.props.element._id)



        return (

            <div className={(this.props.useAccordion ? "accordion" : "") + " option-component " + containerClassName + selectedClassName}
                 id={"opzione_" + this.props.element._id}>

                {this.props.useAccordion ?
                <button className="btn btn-link"
                        onClick={() => this.setOpen()}
                        data-toggle="collapse"
                        data-target={'#collapse' + this.props.element._id}
                        aria-expanded={this.state.isOpen}
                        aria-controls={'collapse' + this.props.element._id}>
                    {(this.state.isOpen ? '-' : '+')} &nbsp;{this.props.labelText}
                </button> :''}


                <div id={'collapse' + this.props.element._id}
                     in={this.state.isOpen.toString()}
                     className={(this.props.useAccordion ? "collapse" : "") + " " + (this.state.isOpen ? 'show' : '')}
                     aria-labelledby="headingOne"
                     data-parent={"#opzione_" + this.props.element._id}>
                <span data-option-id={this.props.element._id}
                      className={className + disabledClassName}>

                    <FieldOpzione
                        selectedElements={this.props.selectedElements}
                        isNewRecord={this.props.isNewRecord}
                        opzioniModificate={this.props.opzioniModificate}
                        vincoliOpzioni={this.props.vincoliOpzioni}
                        convalidaPreventivo={this.props.convalidaPreventivo}
                        onChange={this.props.onChange}
                        element={this.props.element}
                        parentElement={this.props.parentElement}
                        showLabel={(this.props.useAccordion)? 1 : 0}
                        language={this.props.language}
                        t={t}/>

                    {isRequiredBy && isRequiredBy.map((item, index)=>{

                        let labelText = treeOpzioni.getTextInLangArr(item.nameArr, this.props.language)

                        return(<div key={index} className="form-label text-danger pl-3"><small>{t('requires')}: {labelText}</small></div>)
                    })}


                </span>

                {(this.props.convalidaPreventivo.isValid === false)
                && (this.props.currentOptionId === this.props.element._id)
                    && hasErrors ?
                    <ConfigurationErrorMessage
                        title={t('configurationErrorMessageTitle')}
                    handleConfermaConflitto={this.props.handleConfermaConflitto}
                    handleAnnullaConfermaConflitto={this.props.handleAnnullaConfermaConflitto}
                    configurationErrors={this.props.configurationErrors}
                    text={this.props.currentOptionId}
                    element={this.props.element}
                    language={this.props.language}
                    t={t} /> : ''}

                {this.state.element.children && this.state.element.children.map((item, index)=> {
                    return(<OpzionePreventivo
                            onChange={this.props.onChange}
                            handleConfermaConflitto={this.props.handleConfermaConflitto}
                            handleAnnullaConfermaConflitto={this.props.handleAnnullaConfermaConflitto}
                            filterSelectable={this.props.filterSelectable}
                            key={item._id}
                            element={item}
                            selectedElements={this.props.selectedElements}
                            isNewRecord={this.props.isNewRecord}
                            opzioniModificate={this.props.opzioniModificate}
                            parentElement={this.props.element}
                            convalidaPreventivo={this.props.convalidaPreventivo}
                            vincoliOpzioni={this.props.vincoliOpzioni}
                            currentOptionId={this.props.currentOptionId}
                            configurationErrors={this.props.configurationErrors}
                            language={this.props.language}
                        t={t}>
                        </OpzionePreventivo>
                    )
                })}
                </div>
                </div>)
    }
}

export default withTranslation('translations')(OpzionePreventivo)