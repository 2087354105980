import React, { Component } from 'react'
import api from '../api'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import treeOpzioni from "../utilities/treeOpzioni";


const Update = styled.a`
    color: #ef9b0f;
    cursor: pointer;
`

class UpdateModel extends Component {
    updateModel = event => {
        event.preventDefault()

        window.location.href = `/options/list/${this.props.id}`
    }

    render() {
        return <Update className={this.props.className} onClick={this.updateModel}>{this.props.label}</Update>
    }
}


class ModelsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            movies: [],
            columns: [],
            isLoading: false,
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })


        await api.getModels().then(data=>{
            //console.log('data', data)
            this.setState({
                movies: data,
                isLoading: false,
            })
        })

        /*
        await api.getAllModels().then(movies => {
            console.log(movies.data);
            this.setState({
                movies: movies.data,
                isLoading: false,
            })
        })*/
    }



    render() {
        const { t, i18n } = this.props;
        const { movies } = this.state
        //console.log('TCL: ModelsList -> render -> models', movies)



        let showTable = true

        if (!movies.length) {
            showTable = false
        }
        return (
            <>

                <h1>{t('modelli')}</h1>
                <div className="row row-cols-1 row-cols-md-2">

                    {showTable && (


                        this.state.movies.map((item, index)=> {

                            return(
                                <div className="col mb-4" key={item.key}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title text-uppercase"><UpdateModel label={treeOpzioni.getTextInLangArr(item.nameArr, i18n.language)} id={item.key} className="text-primary" /></h4>

                                            <UpdateModel label={t('modifica') + ' ' + t('opzioni')} id={item.key} />
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    )}
                </div>


            </>
        )
    }
}

export default withTranslation('translations')(ModelsList)

