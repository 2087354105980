import React from "react";
import Nav from 'react-bootstrap/Nav'
import { useAuth } from "../context/auth";

function Logout(props) {
    const { setAuthTokens } = useAuth();

    function logOut() {
        setAuthTokens();
    }

    return (
        <Nav.Link eventKey="logout" onSelect={logOut}>Logout</Nav.Link>
    );
}

export default Logout;