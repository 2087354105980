import React, { Component } from 'react'
import Moment from 'moment';

class FormatDate extends Component {
    constructor(props) {
        super(props)
        Moment.locale('it');
         this.format = 'DD/MM/YYYY'
        //console.log('this.props.showTime', this.props.showTime)

        if(this.props.showTime) this.format = 'DD/MM/YYYY HH:mm'
        this.state = {
            value : ''
        }
    }

    componentDidMount () {
        this.setState({ value: Moment(this.props.value).format(this.format) })
    }


    render() {
        return (
            <span>{Moment(this.props.value).format(this.format)}</span>
        )
    }
}

export default FormatDate