import React, { Component } from 'react'
import api from '../api'
import styled from 'styled-components'
import CommonComponents from '../components/CommonComponents'
import '../style/optionComponent.css'
import {useAuth} from "../context/auth";
import {withTranslation} from "react-i18next";
import FieldsValidator from "../utilities/FieldsValidator";


const Title = styled.h1.attrs({
    className: 'h1',
})``

const Label = styled.label`
    margin: 5px;
`

const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin: 5px;
`

const Button = styled.button.attrs({
    className: `btn btn-primary`,
})`
    margin: 15px 15px 15px 5px;
`

const Checkbox = styled.input.attrs({
    className: 'form-check-input',
})``


const FormErrors = ({formErrors}) =>
    //console.log(formErrors) ||
    <div className='formErrors'>
        {Object.keys(formErrors).map((fieldName, i) => {
            if(formErrors[fieldName] && formErrors[fieldName].length > 0){
                return (
                    <p className="bg-warning p-2" key={i}>{formErrors[fieldName]}</p>
                )
            } else {
                return (<div key={i}></div>);
            }
        })}
    </div>

class UserUpdate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            languageArr : window.languageArr,
            formErrors : {},
            user:{},
            email: '',
            name: '',
            password : '',
            langCode : 'en',
            isAdmin : false,
            isActive : true,
            customData: '',

            companyName: '',
            address: '',
            zipCode: '',
            city: '',
            country: '',
            phoneAreaCode1: '',
            phone1: '',
            phoneAreaCode2: '',
            phone2: '',
            email2: '',
            crmUserId:''
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    formValidate(isNewRecord){

        const { t } = this.props;
        let isValidForm = true,
            formErrors = {
                email: FieldsValidator.required(t('email'), this.state.email, t('isRequired')),
                name: FieldsValidator.required(t('name'), this.state.name, t('isRequired')),
            };


        if(isNewRecord){
            formErrors.password = FieldsValidator.required('password', this.state.password, t('isRequired'))
        }
        //console.log('formErrors', formErrors)


        Object.keys(formErrors).forEach((key) => {
            let value = formErrors[key]
            if(value) isValidForm = false

        });

        console.log('formErrors', formErrors)

        return {formErrors: formErrors, isValidForm : isValidForm}
    }


    handleInputChange(event) {
        //console.log("handleInputChange")
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;


        //console.log(name, value)

        // input-arr
        if(target.className && target.className.indexOf('input-arr')> -1){
            value = value.split(',')
        }

        this.setState({
            [name]: value,    });

    }

    handleUpdate = async () => {
        const { id, email, password, name, langCode, isActive, isAdmin, customData, companyName, address, zipCode, city, country, phoneAreaCode1, phone1, phoneAreaCode2, phone2, email2, crmUserId} = this.state

        let payload = { id, email, name, langCode, isActive, isAdmin, customData, companyName, address, zipCode, city, country, phoneAreaCode1, phone1, phoneAreaCode2, phone2, email2, crmUserId}

        let nextUrl = `/users/list/`
        let isNewRecord = (!id || id==='new') ? true : false


        if(password) payload.password = password
            //console.log('password', password)

        // event.preventDefault()
        console.log('handleUpdate',this.state)
        console.log('payload', payload)



        let formValidate = this.formValidate(isNewRecord)
        let self = this
        this.setState({ formErrors: formValidate.formErrors, isValidForm : formValidate.isValidForm })


        //console.log('formValidate.isValidForm', formValidate.isValidForm)
        if(!formValidate.isValidForm ){
            return
        }

        //console.log('this.props.t',this.props.t)

        if(isNewRecord){
            // nuovo record
            delete  payload.id


            console.log('new user payload', payload)

            await api.insertUser(payload).then(res => {
                //window.location.href = `/options/list/${this.state.modelKey}`
                window.location.href = nextUrl
            }).catch(function (error) {
                // handle error
                //console.log(error);
                //console.log(error.response.data);

                let formErrors = { email: error.response.data}
                let formValidate =  {formErrors: formErrors, isValidForm : false}
                //console.log('formErrors', formErrors);
                self.setState({ formErrors: formValidate.formErrors, isValidForm : formValidate.isValidForm })
            })
        }else {


            await api.updateUser(id, payload).then(res => {
                window.location.href = nextUrl
            }).catch(function (error) {
                // handle error
                //console.log(error);
                console.log(error.response.data.message);
                let message = (error.response.data ? error.response.data.message : error.response.data)

                let formErrors = { email: message}
                let formValidate =  {formErrors: formErrors, isValidForm : false}
                console.log('formErrors', formErrors);
                self.setState({ formErrors: formValidate.formErrors, isValidForm : formValidate.isValidForm })
            })


        }

        //window.location.href = `/options/update/${event.target.dataset.optionId}`
    }

    componentDidMount = async () => {
        const { id } = this.state

        let isNewRecord = true;
        let user = {}


        // edit record
        if(id!=='new'){
            isNewRecord = false
            user = await api.getUserById(id)
            user = user.data
            //modelKey = movie.data.modelKey


            console.log('user', user)

        }

        if(isNewRecord){

            // valori default
            user.isAdmin = this.state.isAdmin
            user.isActive = this.state.isActive
        }


        this.setState({
            isNewRecord: isNewRecord,
            user: user,
            email: user.email,
            name: user.name,
            isAdmin: user.isAdmin,
            isActive: user.isActive,
            customData: user.customData,


            companyName: user.companyName,
            address: user.address,
            zipCode: user.zipCode,
            city: user.city,
            country: user.country,
            phoneAreaCode1: user.phoneAreaCode1,
            phone1: user.phone1,
            phoneAreaCode2: user.phoneAreaCode2,
            phone2: user.phone2,
            email2: user.email2,
            crmUserId : user.crmUserId

        })

    }


    render() {

        const { t } = this.props;

        return (
            <>
                <Title>{t('modifica')  + ' ' + t('utente')} </Title>


                <div className="form-row">
                    <div className="form-group col-md-6">
                        <Label>{t('email')} / {t('nomeUtente')}</Label>
                        <InputText
                            type="email"
                            name="email"
                            id="email"
                            value={this.state.email || ''}
                            className={this.state.formErrors.email ? "form-control is-invalid" : "form-control"}
                            onChange={this.handleInputChange}
                            />
                        <div className="invalid-feedback">
                            {this.state.formErrors.email}
                        </div>

                    </div>

                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <Label>{t('nome')}</Label>
                        <InputText
                            type="text"
                            name="name"
                            id="name"
                            value={this.state.name || ''}
                            className={this.state.formErrors.name ? "form-control is-invalid" : "form-control"}
                            onChange={this.handleInputChange}
                        />
                        <div className="invalid-feedback">
                            {this.state.formErrors.name}
                        </div>

                    </div>

                </div>

                <div className="form-row">
                    <div className="form-group col-md-6">
                        <Label>{t('password')} <small>{t('userFormPasswordHelpText')}</small></Label>
                        <InputText
                            type="text"
                            name="password"
                            id="password"
                            value={this.state.password || ''}
                            className={this.state.formErrors.password ? "form-control is-invalid" : "form-control"}
                            onChange={this.handleInputChange}
                        />
                        <div className="invalid-feedback">
                            {this.state.formErrors.password}
                        </div>

                    </div>

                </div>

                <div className="form-group">

                    <div className="form-check">
                        <Checkbox type="checkbox"  name="isAdmin" id="isAdmin" value="true"
                               checked={(this.state.isAdmin ? true : false)}
                               onChange={this.handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="isAdmin">Admin</label>
                    </div>

                    <div className="form-check">
                        <Checkbox type="checkbox"  name="isActive" id="isActive" value="true"
                                  checked={(this.state.isActive ? true : false)}
                                  onChange={this.handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="isActive">Abilitato</label>
                    </div>


                </div>


                <div className="form-row">
                    <div className="form-group col-md-6">
                        <Label>{t('crmUserId')}</Label>
                        <InputText
                            type="crmUserId"
                            name="crmUserId"
                            id="crmUserId"
                            value={this.state.crmUserId || ''}
                            onChange={this.handleInputChange}
                            className={this.state.formErrors.crmUserId ? "form-control is-invalid" : "form-control"}
                        />
                        <div className="invalid-feedback">
                            {this.state.formErrors.crmUserId}
                        </div>
                    </div>
                </div>




                <div className="card bg-light">

                    <div className="card-body">
                        <h4 className="card-title text-uppercase">{t('dealer')}</h4>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <Label>{t('ragioneSociale')}</Label>
                                <InputText
                                    type="text"
                                    name="companyName"
                                    id="companyName"
                                    value={this.state.companyName || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.companyName ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.companyName}
                                </div>
                            </div>



                            <div className="form-group col-md-6">
                                <Label>{t('email2')}</Label>
                                <InputText
                                    type="email2"
                                    name="email2"
                                    id="email2"
                                    value={this.state.email2 || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.email2 ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.email2}
                                </div>
                            </div>

                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <Label>{t('address')}</Label>
                                <InputText
                                    type="text"
                                    name="address"
                                    id="address"
                                    value={this.state.address || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.address ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.address}
                                </div>
                            </div>

                            <div className="form-group col-md-2">
                                <Label>{t('zipCode')}</Label>
                                <InputText
                                    type="text"
                                    name="zipCode"
                                    id="zipCode"
                                    value={this.state.zipCode || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.zipCode ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.zipCode}
                                </div>
                            </div>


                            <div className="form-group col-md-2">
                                <Label>{t('city')}</Label>
                                <InputText
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={this.state.city || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.city ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.city}
                                </div>
                            </div>

                            <div className="form-group col-md-2">
                                <Label>{t('country')}</Label>
                                <InputText
                                    type="text"
                                    name="country"
                                    id="country"
                                    value={this.state.country || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.country ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.country}
                                </div>
                            </div>


                        </div>

                        <div className="form-row">

                            <div className="form-group col-4 col-md-2">
                                <Label>{t('phoneAreaCode')}</Label>
                                <InputText
                                    type="text"
                                    name="phoneAreaCode1"
                                    id="phoneAreaCode1"
                                    value={this.state.phoneAreaCode1 || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.phoneAreaCode1 ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.phoneAreaCode1}
                                </div>
                            </div>


                            <div className="form-group col-12 col-md-4">
                                <Label>{t('phone')}</Label>
                                <InputText
                                    type="text"
                                    name="phone1"
                                    id="phone1"
                                    value={this.state.phone1 || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.phone1 ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.phone1}
                                </div>
                            </div>



                            <div className="form-group col-4 col-md-2">
                                <Label>{t('phoneAreaCode')}</Label>
                                <InputText
                                    type="text"
                                    name="phoneAreaCode2"
                                    id="phoneAreaCode2"
                                    value={this.state.phoneAreaCode2 || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.phoneAreaCode2 ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.phoneAreaCode2}
                                </div>
                            </div>


                            <div className="form-group col-12 col-md-4">
                                <Label>{t('fax')}</Label>
                                <InputText
                                    type="text"
                                    name="phone2"
                                    id="phone2"
                                    value={this.state.phone2 || ''}
                                    onChange={this.handleInputChange}
                                    className={this.state.formErrors.phone2 ? "form-control is-invalid" : "form-control"}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.phone2}
                                </div>
                            </div>


                        </div>


                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <Label>{t('customData')}</Label>
                                <textarea
                                    name="customData"
                                    id="customData"
                                    rows="4"
                                    value={this.state.customData || ''}
                                    className={this.state.formErrors.customData ? "form-control is-invalid" : "form-control"}
                                    onChange={this.handleInputChange}
                                />
                                <div className="invalid-feedback">
                                    {this.state.formErrors.customData}
                                </div>

                            </div>

                        </div>


                    </div>
                </div>




                <div className="container mt-2 mb-3">

                    <div className="panel panel-default">
                        <FormErrors formErrors={this.state.formErrors} />
                    </div>


                    <Button onClick={this.handleUpdate}>{t('salva')}</Button>
                    <CommonComponents.CancelButton href={'/users/list/'}>{t('annulla')}</CommonComponents.CancelButton>

                </div>



            </>
        )
    }

}

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const auth = useAuth();
        //console.log('withMyHook authTokens', auth)
        return <Component {...props} authTokens={auth.authTokens} />;
    }
}

export default withTranslation('translations')(withMyHook(UserUpdate))