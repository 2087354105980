import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge'
import NumberFormat from 'react-number-format'
import { FaExclamationTriangle, FaCheck } from 'react-icons/fa';
import api, {getAllUsers} from '../api'
import { useAuth } from "../context/auth";
import OpzionePreventivo from '../components/OpzionePreventivo'
import treeOpzioni from '../utilities/treeOpzioni'
import Moment from 'moment';
import FormatCurrency from '../utilities/FormatCurrency'
import Utilities from '../utilities/Utilities'
import FieldsValidator from '../utilities/FieldsValidator'
import styled from 'styled-components'
import CommonComponents from '../components/CommonComponents'
import {BsBoxArrowInUpRight} from 'react-icons/bs'
import '../style/commons.css'
import '../style/preventivo.css'

import { withTranslation } from 'react-i18next'


const queryString = require('query-string');

const Label = styled.label`
    margin: 5px;
`

const InputText = styled.input.attrs({})`
    margin: 5px;
    className: ${props => props.className};
`

const FormErrors = ({formErrors}) =>
    //console.log(formErrors) ||
    <div className='formErrors'>
        {Object.keys(formErrors).map((fieldName, i) => {
            if(formErrors[fieldName] && formErrors[fieldName].length > 0){
                return (
                    <p className="bg-warning p-2" key={i}>{formErrors[fieldName]}</p>
                )
            } else {
                return (<div key={i}></div>);
            }
        })}
    </div>



class CrmContactsSearchResults  extends Component{
    constructor(props) {
        super(props);
    }

    render(){

        //console.log('CrmContactsSearchResults contactsList', this.props.contactsList)

        return (
            <ListGroup as="ol">

                {(
                    this.props.contactsList && this.props.contactsList.map((item, index)=> {
                        //console.log(item)
                        let itemIcon = <FaExclamationTriangle />,
                            itemCssClass = 'warning'
                        if(this.props.crmUserId && + item.properties.hubspot_owner_id){
                            itemIcon = <FaCheck />
                            itemCssClass = 'success'
                        }


                        return(

                            <ListGroup.Item  key={item.id}  data-id={item.id} eventKey={item.id}
                                             onClick={this.props.handleSelectCrmContact.bind(this)}
                                             as="li"
                                             className="d-flex justify-content-between align-items-start">

                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.properties.email}</div>
                                    {item.properties.firstname} {item.properties.lastname} {item.properties.city}
                                </div>
                                <Badge variant={itemCssClass} pill>
                                    {itemIcon}
                                </Badge>


                            </ListGroup.Item>
                        )
                    })
                )}

            </ListGroup>
        );
    }
}

class LinkConfiguratore extends Component{

    render() {
        let url =  (this.props.model && this.props.model.webPageUrl) ? this.props.model.webPageUrl + this.props.language : false
        let Link;

        if (url) {
            Link = <a href={url} className="text-decoration-none text-uppercase" target="configurator">{this.props.label} <BsBoxArrowInUpRight/></a>;
        } else {
            Link = <></>;
        }

        return (
            <>
                {Link}
            </>
        );
    }
}

class CustomOption extends Component {

    render() {
        return(
            <div className="form-row my-2">
                <div className="col-12 col-md-9">
                    <textarea
                        data-optionindex={this.props.id}
                        data-name="text"
                        className="form-control"
                        name={'custom_option_text'}
                        value={this.props.text}
                        placeholder="descrizione"
                        onChange={this.props.onChange} />
                </div>
                <div className="col-9 col-md-2">
                    <input
                        data-optionindex={this.props.id}
                        data-name="price"
                        className="form-control text-right"
                        type="number"
                        name={'custom_option_price'}
                        value={this.props.price == null ? "" : this.props.price}
                        placeholder="€"
                        onChange={this.props.onChange} />
                </div>
                <div className="col-3 col-md-1 text-right">
                    <Button data-optionindex={this.props.id}
                            onClick={this.props.onClick}
                            variant="outline-warning"
                            size="sm">-</Button>
                </div>
            </div>)
    }
}

class PreventivoUpdate extends Component {
    constructor(props) {
        super(props)
        Moment.locale('it');

        let userId = this.props.authTokens ? this.props.authTokens.email : ''
        let crmUserId = this.props.authTokens ? this.props.authTokens.crmUserId : null

        this.state = {
            id: this.props.match.params.id,
            modelKey: '',
            userId: userId,
            crmUserId : crmUserId,
            crmContactOwnerState: '', // unset (contact non ha owner), match, conflict
            name: '',
            opzioniArr : [],
            opzioni : [],
            opzioniModello: [],
            price : 0,
            crmContactsSearch: 'email',

            customerFirstName: '',
            customerLastName: '',
            customerEmail: '',
            customerAddress: '',
            customerPhone: '',
            customerOwnedBoat: '',
            customerNavigationArea: '',
            notes: '',
            crmCode: '',
            languageCode: '',

            discount: '',
            freightCharges: '',
            tradeInValue: '',
            tradeInDescription: '',
            validity: '',
            deliveryv: '',
            paymentTerms: '',

            customerCity: '',
            customerCountry: '',
            customerZipCode: '',
            customerPhoneAreaCode: '',

            columns: [],
            customOptions : [],
            opzioniFixed:[],
            formErrors : {},
            formValidate : false,
            customerInputDataDisabled: true,
            vincoliOpzioni: [],
            convalidaPreventivo: {isValid: false, errors: [], messages: [],
            isLoading: false,
            model: {},
            crmContactSearch : this.props.t('crmContactSearch'),
            crmContactsList: [],
            allUsers : [],
            opzioniModificate: []}
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectCrmContact = this.handleSelectCrmContact.bind(this);
        this.handleCrmSearch = this.handleCrmSearch.bind(this)


    }

    filtraContatti(allUsers, crmContactsList){
        //console.log('filtraContatti', allUsers)
        //console.log('filtraContatti crmContactsList', crmContactsList)

        if(!allUsers || !crmContactsList) return this.state.crmContactsList

        let filterCrmContactsList = []

        crmContactsList.forEach(element => {
            let filter = false
            let email = element.properties.email
            const result = allUsers.filter(user => user.email == email);

            if(result && result.length && result[0].crmUserId){
                filter = true
            }

            if(!filter) filterCrmContactsList.push(element)


        });

        return filterCrmContactsList
    }

    calcolaPrezzo(opzioniModello, opzioniArr, customOptions) {
        return treeOpzioni.calcolaPrezzo(opzioniModello, opzioniArr, customOptions)
    }

    calcolaPrezzoNetto(){
        //console.log('calcolaPrezzoNetto', this.state.discount)
        //return Number(this.state.price) + Number(this.state.discount) + Number(this.state.freightCharges) + Number(this.state.tradeInValue)
        return Number(this.state.price) - Number(this.state.discount) + Number(this.state.freightCharges) - Number(this.state.tradeInValue)
    }

    formValidate(){

        const { t } = this.props;
        let isValidForm = true,
            formErrors = {
                userId: FieldsValidator.required('userId', this.state.userId, t('isRequired')),
                name: FieldsValidator.required('name', this.state.name, t('isRequired')),
                modelKey: FieldsValidator.required('modelKey', this.state.modelKey, t('isRequired')),
                customerFirstName: FieldsValidator.required(t('customerFirstName'), this.state.customerFirstName, t('isRequired')),
                customerLastName: FieldsValidator.required(t('customerLastName'), this.state.customerLastName, t('isRequired')),
                customerEmail: FieldsValidator.email(t('customerEmail'), this.state.customerEmail, t('invalidEmail')),
            };

        Object.keys(formErrors).forEach((key) => {
            let value = formErrors[key]
            if(value) isValidForm = false

        });

        console.log('formErrors', formErrors)

        return {formErrors: formErrors, isValidForm : isValidForm}
    }

    /**
     *
     * @param event
     * @param argMap {elementId: String, optionArrToUnselect: [String], optionArrToSelect: [String]}
     */
    handleConfermaConflitto = (event, argMap) => {
        event.preventDefault()
        /*
        console.log('handleConfermaConflitto currentOptionId', this.state.currentOptionId)
        console.log('handleConfermaConflitto currentOptionProposedValue', this.state.currentOptionProposedValue)
        console.log('handleConfermaConflitto opzioniArr', this.state.opzioniArr)
        console.log('handleConfermaConflitto convalidaPreventivo', this.state.convalidaPreventivo)
        */

        let self = this,
            price

        //console.log('handleConfermaConflitto argMap',argMap)

        let currentOptionId = this.state.currentOptionId,
            opzioniArrCopy = [...this.state.opzioniArr],
            optionArrToUnselect = argMap.optionArrToUnselect,
            optionArrToSelect = argMap.optionArrToSelect

        //console.log('handleConfermaConflitto currentOptionId',currentOptionId)
        //console.log('handleConfermaConflitto opzioniArrCopy',opzioniArrCopy)


        // tolgo da opzioniArrCopy gli elementi di optionArrToUnselect

        optionArrToUnselect.forEach(function (item) {
            opzioniArrCopy = opzioniArrCopy.filter(function(value, index, arr){
                console.log('filter', value)
                return !(value.id === item || value.text === item);
            });

        })
        //console.log('after remove opzioniArrCopy', opzioniArrCopy)

        // aggiungo a optionArrToSelect currentOptionId (l'opzione che l'utente ha selezionato e per cui è apparsa la finestra di avviso)

        optionArrToSelect.push(currentOptionId)

        // seleziono gli elementi di optionArrToSelect
        // prima di aggiungere un elemento devo controllare se e' in un gruppo a scelta singola
        // in questo caso devo selezionare anche il gruppo: id= codice gruppo, text=codice opzione

        optionArrToSelect.forEach(function (item) {
            //console.log('optionArrToSelect', item)

            let option = treeOpzioni.lookupOptionById(item, self.state.opzioniModello)
            let optionParent

            if(option && option.gruppo) optionParent = treeOpzioni.lookupOptionById(option.gruppo, self.state.opzioniModello)
            //console.log('currentOptionParent', currentOptionParent)

            if(optionParent && !optionParent.isMultiple){
                opzioniArrCopy.push({id: optionParent._id, text: item})
            }else{
                opzioniArrCopy.push({id: item, text: true})
            }
        })

        //console.log('opzioniArrCopy after', opzioniArrCopy)

        price = this.calcolaPrezzo(this.state.opzioniModello, opzioniArrCopy,  this.state.customOptions);
        this.setState({opzioniArr : opzioniArrCopy, currentOptionId: null, price: price})

        if(argMap) return;



        // ------------------- VECCHIO CODICE -------------------


        const filtraOpzioniSelezionate = function (element) {
            //console.log('** filtraOpzioniSelezionate currentErroreConf **', currentErroreConf)
            //console.log('filtraOpzioniSelezionate element', element)
            let arrayToSearch = [currentErroreConf.who, currentErroreConf.what]

            return !(arrayToSearch.includes(element.id) || arrayToSearch.includes(element.text))

        }

        /*

        elenco errori del componente: cerca state.currentOptionId in state.convalidaPreventivo.errors.who or what

         */

        let erroriExcludesCurrentOption = this.state.convalidaPreventivo.errors.filter(optionError =>{
            return (optionError.who === currentOptionId || optionError.what === currentOptionId) && optionError.type === "excludes"
        })

        let erroriRequiresCurrentOption = this.state.convalidaPreventivo.errors.filter(optionError =>{
            return (optionError.who === currentOptionId || optionError.what === currentOptionId) && optionError.type === "requires"
        })

        //console.log('erroriCurrentOption',erroriExcludesCurrentOption)



        // DESELEZIONE DEI COMPONENTI

        // ----- per ogni "excludes" di erroriCurrentOption filtro opzioniArrCopy -----

        let currentErroreConf;

        //console.log('opzioniArrCopy before filter',opzioniArrCopy)
        erroriExcludesCurrentOption.forEach(erroreConf=>{
            currentErroreConf = erroreConf
            opzioniArrCopy = opzioniArrCopy.filter(filtraOpzioniSelezionate)
            //console.log('erroreConf',erroreConf)
        })



        // ----- per ogni "requires" di erroriCurrentOption filtro opzioniArrCopy -----


        //console.log('opzioniArrCopy before filter requires',opzioniArrCopy)
        erroriRequiresCurrentOption.forEach(erroreConf=>{
            currentErroreConf = erroreConf
            opzioniArrCopy = opzioniArrCopy.filter(filtraOpzioniSelezionate)
            //console.log('erroreConf',erroreConf)
        })
        //console.log('opzioniArrCopy dopo erroriExcludesCurrentOption',opzioniArrCopy)

        // SELEZIONE DEI COMPONENTI REQIURED
        // resetto  opzioniArrCopy
        //opzioniArrCopy = [...this.state.opzioniArr]

        /*
        console.log('SELEZIONE DEI COMPONENTI REQIURED')
        console.log('opzioniArrCopy before filter',opzioniArrCopy)
        console.log('this.state.convalidaPreventivo.errors',this.state.convalidaPreventivo.errors)
        console.log('erroriRequiresCurrentOption', erroriRequiresCurrentOption)
        */

        let opzioniDaSelezionare = []

        erroriRequiresCurrentOption.forEach(erroreConf=>{
            currentErroreConf = erroreConf

            /*
            console.log('-currentErroreConf before search errore conf', currentErroreConf)
            console.log('-currentErroreConf before search errore conf', currentErroreConf)
            console.log('-opzioniArrCopy before search errore conf', opzioniArrCopy)
            console.log('-len filter', opzioniArrCopy.filter(filtraOpzioniSelezionate).length)
            */


            let idOpzioneDaSelezionare  = currentErroreConf.who === currentOptionId ? currentErroreConf.what : currentErroreConf.who
            //console.log('>currentOptionId',currentOptionId)
            if(this.state.currentOptionProposedValue) opzioniDaSelezionare.push({id: idOpzioneDaSelezionare, text: true})

            /*
            if(opzioniArrCopy.filter(filtraOpzioniSelezionate).length < 1){
                console.log('>currentErroreConf',currentErroreConf)
                //console.log('>opzioniArrCopy',opzioniArrCopy)
                let idOpzioneDaSelezionare  = currentErroreConf.who === currentOptionId ? currentErroreConf.what : currentErroreConf.who
                console.log('>currentOptionId',currentOptionId)
                opzioniDaSelezionare.push({id: idOpzioneDaSelezionare, text: true})
                opzioniDaSelezionare.push({id: currentOptionId, text: true})

            }else {
                console.log('erroreConf not found in opzioniArrCopy', erroreConf)
                console.log('erroreConf not found in opzioniArrCopy', opzioniArrCopy)

            }*/

        })

        //console.log('state',this.state)
        // aggiungo l'opzione selezionta dall'utente
        if(this.state.currentOptionProposedValue){
            console.log('*** aggiungo l\'opzione selezionta dall\'utente ***')

            // se è un input radio devo impostare id = parentId , text =  currentOptionId
            // altrimenti id = currentOptionId , text =  true
            // per verificare se è un input radio radio controllo se il gruppo è isMultiple

            let currentOption = treeOpzioni.lookupOptionById(currentOptionId, this.state.opzioniModello)
            let currentOptionParent

            if(currentOption && currentOption.gruppo) currentOptionParent = treeOpzioni.lookupOptionById(currentOption.gruppo, this.state.opzioniModello)
            //console.log('currentOptionParent', currentOptionParent)

            if(currentOptionParent && !currentOptionParent.isMultiple){
                opzioniArrCopy.push({id: currentOptionParent._id, text: currentOptionId})
            }else{
                opzioniArrCopy.push({id: currentOptionId, text: true})
            }

        }

        opzioniArrCopy = opzioniArrCopy.concat(opzioniDaSelezionare)
        //console.log('opzioniArrCopy after concat',opzioniArrCopy)

        price = this.calcolaPrezzo(this.state.opzioniModello, opzioniArrCopy,  this.state.customOptions);

        this.setState({opzioniArr : opzioniArrCopy, currentOptionId: null, price: price})

    }

    handleAnnullaConfermaConflitto = event => {
        event.preventDefault()
        let convalidaPreventivo = treeOpzioni.convalidaPreventivo(this.state.opzioniModello, this.state.opzioniArr)

        // forzo isValid per evitare un blocco se la configurazione di partenza non è valida
        convalidaPreventivo.isValid = true

        this.setState({
            convalidaPreventivo: convalidaPreventivo
        });
    }

    addCustomOption = event => {

        let customOptions = this.state.customOptions

        // controlla se ci sono customOptions vuote
        if(customOptions.findIndex(myOpzione => myOpzione.text.trim().length === 0) > -1) return


        let index = Utilities.randomString(6).toLowerCase()

        let option = {"id":index, "text":"", price: null}
        customOptions.push(option)

        //console.log(customOptions)

        this.setState({
            customOptions : customOptions});

    }

    deleteCustomOption = event => {

        let customOptions = this.state.customOptions
        let optionIndex = event.target.dataset.optionindex.toString()
        let index = customOptions.findIndex(myOpzione => myOpzione.id === optionIndex)

        //console.log('deleteCustomOption optionIndex', optionIndex)
        //console.log('deleteCustomOption index', index)

        if(index>-1) {
            customOptions.splice(index, 1)

            this.setState({
                customOptions : customOptions});
        }
    }

    handleCustomOptionChange = event => {
        event.preventDefault()
        //console.log('handleCustomOptionChange',event.target.dataset.index)
        const target = event.target;

        let id = event.target.dataset.optionindex.toString()
        let name = event.target.dataset.name
        let value = target.value

        let customOptions = this.state.customOptions


        //console.log('handleCustomOptionChange',customOptions)
        //console.log('handleCustomOptionChange',id)

        let option = customOptions.find(myOpzione => myOpzione.id === id)

        if(name==="price" && !isNaN(value)) value = Number(value)

        if(option){
            option[name] = value
        }

        //console.log('handleCustomOptionChange',option)

        let price = this.calcolaPrezzo(this.state.opzioniModello, this.state.opzioniArr,  customOptions);


        this.setState({
            customOptions: customOptions,
            price: price

        });

    }

    handleInputChange(event) {
        //console.log("handleInputChange")
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;


        //console.log('handleInputChange name value ' + name, value)
        //console.log('this.state.modelKey', this.state.modelKey)


        let price = this.calcolaPrezzo(this.state.opzioniModello, this.state.opzioniArr, this.state.customOptions);


        this.setState({
            [name]: value,
            price : price});

    }

    handleOpzioneChange = event => {
        //event.preventDefault()
        //console.log('handleOpzioneChange',event.target.dataset.optionId)
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let opzioniArr = this.state.opzioniArr,
            opzioniModificate = [...this.state.opzioniModificate]


        //console.log('event.target', event.target)
        //console.log(name, value)


        let inArray = opzioniModificate.find(element => element === name);
        if(!inArray) opzioniModificate.push(name)


        //console.log('e.target.defaultValue', event.target.defaultValue)


        let opzione = {"id": name, "text" : value};

        //let currentOptionId = opzione.id
        let currentOptionId = target.type === 'radio' ? target.value : opzione.id

        //console.log('opzione', opzione)

        // crea una copia di opzioniArr per verificare se la configurazione è valida

        let originalOpzioniArr = [...opzioniArr];

        //console.log('opzioniArr beore update', opzioniArr.length)
        //console.log('originalOpzioniArr before update', originalOpzioniArr.length)
        //console.log('this.state.opzioniModello', this.state.opzioniModello)

        const index = originalOpzioniArr.findIndex(myOpzione => myOpzione.id === (name));

        if(index < 0){
            originalOpzioniArr.push(opzione)
        }else {

            if(value) {
                originalOpzioniArr[index] = opzione
            }else {
                originalOpzioniArr.splice(index, 1);
            }
        }

        //console.log('opzioniArr after update', opzioniArr.length)
        //console.log('originalOpzioniArr after update', originalOpzioniArr.length)

        let convalidaPreventivo = treeOpzioni.convalidaPreventivo(this.state.opzioniModello, originalOpzioniArr)
        //console.log('handleOpzioneChange convalidaPreventivo', convalidaPreventivo)

        if(!convalidaPreventivo.isValid){
            //console.log('-------- convalidaPreventivo.isValid', convalidaPreventivo.isValid)
            //console.log('-------- convalidaPreventivo', convalidaPreventivo)


            /*
            console.log('handleOpzioneChange opzioniArr on invalid config', opzioniArr)
            console.log('handleOpzioneChange value after', value)
            console.log('handleOpzioneChange value after currentOptionId', currentOptionId)
            */


            event.preventDefault()
            this.setState({
                currentOptionId: currentOptionId,
                currentOptionProposedValue: value,
                convalidaPreventivo: convalidaPreventivo
            });


            //console.log('handleOpzioneChange state after', this.state)

            return


        } // end !convalidaPreventivo.isValid

        //console.log('handleOpzioneChange this.state.customOptions', this.state.customOptions)
        let price = this.calcolaPrezzo(this.state.opzioniModello, originalOpzioniArr,  this.state.customOptions);


        //console.log('>opzioniArr', opzioniArr)

        this.setState({
            opzioniArr: originalOpzioniArr,
            price: price,
            convalidaPreventivo: convalidaPreventivo,
            requiredErrors: convalidaPreventivo.requiredErrors,
            currentOptionId: currentOptionId,
            currentOptionProposedValue: value,
            opzioniModificate: opzioniModificate
        });

        //console.log('handleOpzioneChange state end', this.state)

    }

    handleUpdate = async () => {
        const { id, userId,  modelKey, name, price, opzioniArr, customOptions,
            customerFirstName,
            customerLastName,
            customerEmail,
            customerAddress,
            customerPhone,
            customerOwnedBoat,
            customerNavigationArea,
            notes,
            crmCode,
            languageCode,
            discount,
            freightCharges,
            tradeInValue,
            tradeInDescription,
            validity,
            delivery,
            paymentTerms,
            customerCity,
            customerCountry,
            customerZipCode,
            customerPhoneAreaCode
        } = this.state

        let payload = { id, userId, modelKey, name, price, opzioniArr, customOptions,
            customerFirstName,
            customerLastName,
            customerEmail,
            customerAddress,
            customerPhone,
            customerOwnedBoat,
            customerNavigationArea,
            notes,
            crmCode,
            languageCode,
            discount,
            freightCharges,
            tradeInValue,
            tradeInDescription,
            validity,
            delivery,
            paymentTerms,
            customerCity,
            customerCountry,
            customerZipCode,
            customerPhoneAreaCode
        }

        let nextUrl = `/preventivi/list/`
        // event.preventDefault()
        console.log('handleUpdate',this.state)
        console.log('payload', payload)



        let formValidate = this.formValidate()
        this.setState({ formErrors: formValidate.formErrors, isValidForm : formValidate.isValidForm })



        let convalidaPreventivo = treeOpzioni.convalidaPreventivo(this.state.opzioniModello, this.state.opzioniArr)
        console.log('convalidaPreventivo', convalidaPreventivo)

        //if(!convalidaPreventivo.isValid) return

        if(!convalidaPreventivo.isValidRequired){
            this.setState({requiredErrors : convalidaPreventivo.requiredErrors})
        }

        if(!formValidate.isValidForm || !convalidaPreventivo.isValid || !convalidaPreventivo.isValidRequired){
            return
        }

        //http://localhost:3000/pdf?id=6093307491972c2da6b21018
        console.log('customerEmail',payload.customerEmail)
        //if(1>0) return

        if(!id){
            // nuovo record
            await api.insertPreventivo(payload).then(res => {

                // aggiorna crm
                let port = window.location.port == '80' ? '' : ':' + window.location.port
                port = ''
                let host = window.location.protocol + "//" + window.location.hostname + port

                let owner = null;
                if(['conflict', 'unset'].includes(this.state.crmContactOwnerState)){
                    owner = this.state.crmUserId
                    console.log('conflict or unset', )
                }

                //console.log('this.state.crmContactOwnerState', this.state.crmContactOwnerState)
                //console.log('this.state.crmUserId', this.state.crmUserId)

                let payloadCrm = {link_preventivatore: host + "/pdf?id=" + res.data._id + ' ' + new Date().toLocaleDateString("it-IT") + (owner ? ' ' + owner : '')};
                //console.log(payloadCrm)
                api.updateCrmContact(payload.customerEmail, payloadCrm)
                window.location.href = nextUrl
            })
        }else {

            await api.updatePreventivoById(id, payload).then(res => {
                window.location.href = nextUrl
            })

        }

        //window.location.href = `/options/update/${event.target.dataset.optionId}`
    }

    handleCrmSearch(event){
        //const target = event.target;
        console.log('this.state.crmContactSearch', this.state.crmContactSearch)

        api.findCrmContactsByEmail(this.state.crmContactSearch).then(res => {
            //console.log('res', res.data.results)
            let crmContactsList = res.data.results

            // se trova un solo contatta lo imposta nel form
            if(crmContactsList && crmContactsList.length ==1){
                //console.log('setCrmContact', crmContactsList[0])
                if(crmContactsList[0]) this.setCrmContact(crmContactsList[0].properties)
            }

            this.setState({
                crmContactsList : crmContactsList});
        })
    }

    setCrmContact (crmContactData){
        //console.log("set crmContactData", crmContactData)
        let crmContactOwnerState = 'unset'
        if(crmContactData.hubspot_owner_id && this.state.crmUserId){
            crmContactOwnerState = 'conflict'
            if(crmContactData.hubspot_owner_id == this.state.crmUserId){
                crmContactOwnerState = 'match'
            }
        }

        console.log("crmContactOwnerState", crmContactOwnerState)

        this.setState({
                customerEmail: crmContactData.email,
                customerFirstName:crmContactData.firstname,
                customerLastName : crmContactData.lastname,
                customerCity : crmContactData.city,
                customerZipCode : crmContactData.zip,
                //customerCountry : crmContactData.nazione,
                customerAddress : crmContactData.address,
                customerCountry : crmContactData.state,
                customerPhone : crmContactData.phone,
                customerNavigationArea : crmContactData.area_di_navigazione,
                crmContactOwnerState : crmContactOwnerState
            }
        );

    }

    handleSelectCrmContact(event) {
        event.preventDefault()
        //console.log("handleSelectCrmContact event", event.target.dataset.id)
        let id = event.target.dataset.id

        //console.log("handleSelectCrmContact this.state.crmContactsList", this.state.crmContactsList)

        let crmContact = this.state.crmContactsList.find(x => x.id === id);
        if(!crmContact) return
        //let crmContactData = crmContact.properties

        //console.log("handleSelectCrmContact hubspot_owner_id", crmContactData.hubspot_owner_id)
        //console.log("this.state.crmUserId", this.state.crmUserId)
        //console.log("crmContact", crmContact)
        this.setCrmContact(crmContact.properties)

    }

    componentDidMount = async () => {

        //console.log('componentDidMount', this.props)
        const parsed = queryString.parse(window.location.search);


        let isNewRecord = true,
            id = parsed.id,
            modelKey = this.state.modelKey,
            name = this.state.name,
            userId = this.state.userId,
            opzioniArr = this.state.opzioniArr,
            crmContactsList = [],
            allUsers = [],

            customerFirstName= this.state.customerFirstName,
            customerLastName= this.state.customerLastName,
            customerEmail= this.state.customerEmail,
            customerAddress= this.state.customerAddress,
            customerPhone = this.state.customerPhone,
            customerOwnedBoat= this.state.customerOwnedBoat,
            customerNavigationArea = this.state.customerNavigationArea,
            notes= this.state.notes,
            crmCode= this.state.crmCode,
            languageCode= this.state.languageCode,

            discount= this.state.discount,
            freightCharges= this.state.freightCharges,
            tradeInValue= this.state.tradeInValue,
            tradeInDescription = this.state.tradeInDescription,
            validity= this.state.validity,
            delivery= this.state.delivery,
            paymentTerms= this.state.paymentTerms,


            customerCity= this.state.customerCity,
            customerCountry= this.state.customerCountry,
            customerZipCode= this.state.customerZipCode,
            customerPhoneAreaCode= this.state.customerPhoneAreaCode,

            crmContactsSearch = this.state.crmContactsSearch,

            customOptions = this.state.customOptions,
            requiredErrors = []

        this.setState({ isLoading: true })

        if(id) isNewRecord = false


        if(isNewRecord){
            if(parsed.modelKey) modelKey = parsed.modelKey;
            //let userId = this.props.authTokens ? this.props.authTokens.email : 'zzz'
            //console.log('modelKey da query string', modelKey)
            name  = modelKey.toUpperCase() + ' ' + Moment(new Date()).format('DD/MM/YYYY HH:mm')

            crmContactsList = await api.findCrmContactsAll('email_to_search')
            //console.log('crmContactsList', crmContactsList.data.results);
            crmContactsList = crmContactsList.data.results

            allUsers = await api.getAllUsers()
            //console.log('allUsers', allUsers.data)
            allUsers = allUsers.data

            crmContactsList = this.filtraContatti(allUsers, crmContactsList)

        }

        if(!isNewRecord){

            await api.getPreventivoById(id).then(preventivo => {
                preventivo = preventivo.data
                //console.log('preventivo', preventivo)
                modelKey =  preventivo.modelKey
                name =preventivo.name
                userId= preventivo.userId
                opzioniArr= preventivo.opzioniArr
                customOptions = preventivo.customOptions

                customerFirstName= preventivo.customerFirstName
                customerLastName= preventivo.customerLastName
                customerEmail= preventivo.customerEmail
                customerAddress= preventivo.customerAddress
                customerPhone = preventivo.customerPhone
                customerOwnedBoat= preventivo.customerOwnedBoat
                customerNavigationArea = preventivo.customerNavigationArea
                notes= preventivo.notes
                crmCode= preventivo.crmCode
                languageCode= preventivo.languageCode

                discount= preventivo.discount
                freightCharges= preventivo.freightCharges
                tradeInValue= preventivo.tradeInValue
                tradeInDescription = preventivo.tradeInDescription
                validity= preventivo.validity
                delivery= preventivo.delivery
                paymentTerms= preventivo.paymentTerms

                customerCity= preventivo.customerCity
                customerCountry= preventivo.customerCountry
                customerZipCode= preventivo.customerZipCode
                customerPhoneAreaCode= preventivo.customerPhoneAreaCode
            })

        }

        let model
        await api.getModelByKey(modelKey).then(data => model = data)
        //console.log('----crmContactsList', crmContactsList)
        //console.log('----allUsers', allUsers)


        await api.getOpzioniByModel(modelKey).then(opzioniModello => {
            //console.log(opzioniModello.data);

            let treeOpzioniModello = treeOpzioni.creaStrutturaOpzioni(opzioniModello.data);

            let price = this.calcolaPrezzo(opzioniModello.data, opzioniArr, customOptions)


            let convalidaPreventivo = treeOpzioni.convalidaPreventivo(opzioniModello.data, opzioniArr)
            //console.log('convalidaPreventivo', convalidaPreventivo)

            let vincoliOpzioni = treeOpzioni.getVincoliOpzioni(opzioniModello.data)
            //console.log('vincoliOpzioni', vincoliOpzioni)

            let opzioniFixed = treeOpzioni.getOpzioniFixed(opzioniModello.data)

            this.setState({
                id: id,
                opzioni : treeOpzioniModello,
                opzioniModello: opzioniModello.data,
                isLoading: false,
                modelKey: modelKey,
                name: name,
                userId: userId,
                opzioniArr: opzioniArr,
                customOptions: customOptions,
                price: price,
                convalidaPreventivo: convalidaPreventivo,
                vincoliOpzioni: vincoliOpzioni,
                requiredErrors: requiredErrors,
                opzioniFixed: opzioniFixed,

                customerFirstName: customerFirstName,
                customerLastName: customerLastName,
                customerEmail: customerEmail,
                customerAddress: customerAddress,
                customerPhone : customerPhone,
                customerOwnedBoat: customerOwnedBoat,
                customerNavigationArea : customerNavigationArea,
                notes: notes,
                crmCode: crmCode,
                languageCode: languageCode,
                discount:discount,
                freightCharges:freightCharges,
                tradeInValue:tradeInValue,
                tradeInDescription:tradeInDescription,
                validity:validity,
                delivery:delivery,
                paymentTerms:paymentTerms,

                customerCity: customerCity,
                customerCountry: customerCountry,
                customerZipCode: customerZipCode,
                customerPhoneAreaCode: customerPhoneAreaCode,

                model: model,
                opzioniModificate : [],
                //crmContactsList : crmContactsList,
                allUsers : allUsers,
                isNewRecord: isNewRecord
            })
        })

        //console.log('componentDidMount this.state.opzioniArr', this.state.opzioniArr)

    }

    render() {

        const { t, i18n } = this.props;
        //const { preventivi } = this.state
        //console.log('this.state.requiredErrors',this.state.requiredErrors)
        //console.log('this.state.crmUserId',this.state.crmUserId)

        //let pdfData = this.getPdfData()

        let contactSelect = '',
            crmContactsSearchResults = '';

        let crmContactOwnerStateLabel = (<p className='bg-light p-2'>{t('crmContactOwnerState_unset')}</p>);

        if(this.state.crmContactOwnerState == 'match' ){
            crmContactOwnerStateLabel = (<p className='bg-success p-2'>{t('crmContactOwnerState_match')}</p>)
        }
        if(this.state.crmContactOwnerState == 'conflict' ){
            crmContactOwnerStateLabel = (<p className='bg-danger p-2'>{t('crmContactOwnerState_conflict')}</p>)
        }
        if(this.state.crmContactOwnerState == 'unset' ){
            crmContactOwnerStateLabel = (<p className='bg-warning p-2'>{t('crmContactOwnerState_conflict')}</p>)
        }
        //console.log('this.state.crmContactOwnerState', this.state.crmContactOwnerState)

        if(this.state.crmContactsList && this.state.crmContactsList.length > 1) crmContactsSearchResults = (<CrmContactsSearchResults contactsList={this.state.crmContactsList} crmUserId={this.state.crmUserId} handleSelectCrmContact={this.handleSelectCrmContact} />)

        if(this.state.isNewRecord ){
            contactSelect=(
                <div className="row">

                    <div className="form-group col-md-6">

                        <InputGroup className="mb-3">
                            <InputText
                                placeholder={t('crmContactSearch')}
                                type="text"
                                name="crmContactSearch"
                                id="crmContactSearch"
                                value={this.state.crmContactSearch}
                                onChange={this.handleInputChange}
                                className={this.state.formErrors.modelKey ? "form-control is-invalid" : "form-control"}
                            />

                            <Button variant="primary" onClick={this.handleCrmSearch}>{t('crmSearchButton')}</Button>

                        </InputGroup>

                        {crmContactsSearchResults}

                    </div>

                    <div className="col-6">
                        {crmContactOwnerStateLabel}
                    </div>
                </div>)
        }

        return (

            <>
                <div className="container my-2">

                    <h1 className="my-4 text-uppercase">{t('preventivo')}
                        <span className="text-muted"> {this.state.model && treeOpzioni.getTextInLangArr(this.state.model.nameArr, i18n.language)}</span>
                    </h1>

                    {contactSelect}


                    <div className="row">
                        <div className="col-12 text-right">
                            <LinkConfiguratore model={this.state.model} label={t('configuratore')} language={i18n.language}/>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <Label>{t('model')}</Label>
                            <InputText
                                type="text"
                                name="modelKey"
                                id="modelKey"
                                value={this.state.modelKey || ''}
                                className={this.state.formErrors.modelKey ? "form-control is-invalid" : "form-control"}
                                readOnly />
                            <div className="invalid-feedback">
                                {this.state.formErrors.modelKey}
                            </div>


                        </div>
                        <div className="form-group col-md-4">
                            <Label>{t('utente')}</Label>
                            <InputText
                                type="text"
                                name="userId"
                                id="UserId"
                                value={this.state.userId || ''}
                                onChange={this.handleInputChange}
                                className={this.state.formErrors.userId ? "form-control is-invalid" : "form-control"}
                                readOnly />
                            <div className="invalid-feedback">
                                {this.state.formErrors.userId}
                            </div>
                        </div>


                        <div className="form-group col-md-4">
                            <Label>{t('crmCode')}</Label>
                            <InputText
                                type="text"
                                name="crmCode"
                                id="crmCode"
                                value={this.state.crmCode || ''}
                                onChange={this.handleInputChange}
                                className={this.state.formErrors.crmCode ? "form-control is-invalid" : "form-control"}
                                readOnly />
                            <div className="invalid-feedback">
                                {this.state.formErrors.crmCode}
                            </div>
                        </div>

                    </div>


                    <div className="card bg-light">

                        <div className="card-body">
                            <h4 className="card-title text-uppercase">{t('customer')}</h4>

                            <div className="form-row">

                                <div className="form-group col-md-4">
                                    <Label>{t('customerFirstName')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="text"
                                        name="customerFirstName"
                                        id="customerFirstName"
                                        value={this.state.customerFirstName || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerFirstName ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerFirstName}
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <Label>{t('customerLastName')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="text"
                                        name="customerLastName"
                                        id="customerLastName"
                                        value={this.state.customerLastName || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerLastName ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerLastName}
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <Label>{t('customerEmail')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="email"
                                        name="customerEmail"
                                        id="customerEmail"
                                        value={this.state.customerEmail || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerEmail ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerEmail}
                                    </div>
                                </div>

                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <Label>{t('address')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="text"
                                        name="customerAddress"
                                        id="customerAddress"
                                        value={this.state.customerAddress || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerAddress ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerAddress}
                                    </div>
                                </div>

                                <div className="form-group col-md-2">
                                    <Label>{t('zipCode')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="text"
                                        name="customerZipCode"
                                        id="customerZipCode"
                                        value={this.state.customerZipCode || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerZipCode ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerZipCode}
                                    </div>
                                </div>


                                <div className="form-group col-md-2">
                                    <Label>{t('city')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="text"
                                        name="customerCity"
                                        id="customerCity"
                                        value={this.state.customerCity || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerCity ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerCity}
                                    </div>
                                </div>

                                <div className="form-group col-md-2">
                                    <Label>{t('country')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="text"
                                        name="customerCountry"
                                        id="customerCountry"
                                        value={this.state.customerCountry || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerCountry ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerCountry}
                                    </div>
                                </div>


                            </div>

                            <div className="form-row">

                                <div className="form-group col-4 col-md-2">
                                    <Label>{t('phoneAreaCode')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="text"
                                        name="customerPhoneAreaCode"
                                        id="customerPhoneAreaCode"
                                        value={this.state.customerPhoneAreaCode || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerPhoneAreaCode ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerPhoneAreaCode}
                                    </div>
                                </div>


                                <div className="form-group col-12 col-md-4">
                                    <Label>{t('phone')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="text"
                                        name="customerPhone"
                                        id="customerPhone"
                                        value={this.state.customerPhone || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerPhone ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerPhone}
                                    </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <Label>{t('customerNavigationArea')}</Label>
                                    <InputText
                                        disabled={this.state.customerInputDataDisabled}
                                        type="text"
                                        name="customerNavigationArea"
                                        id="customerNavigationArea"
                                        value={this.state.customerNavigationArea || ''}
                                        onChange={this.handleInputChange}
                                        className={this.state.formErrors.customerNavigationArea ? "form-control is-invalid" : "form-control"}
                                    />
                                    <div className="invalid-feedback">
                                        {this.state.formErrors.customerNavigationArea}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="form-row mt-4">
                        <div className="form-group col-md-12">
                            <Label>{t('titoloPreventivo')}</Label>
                            <InputText
                                type="text"
                                name="name"
                                id="name"
                                value={this.state.name || ''}
                                onChange={this.handleInputChange}
                                className={this.state.formErrors.name ? "form-control is-invalid" : "form-control"}/>
                            <div className="invalid-feedback">
                                {this.state.formErrors.name}
                            </div>
                        </div>

                        <div className="form-group col-md-12">
                            <Label>{t('notes')}</Label>
                            <textarea
                                name="notes"
                                id="notes"
                                onChange={this.handleInputChange}
                                className={this.state.formErrors.notes ? "form-control is-invalid" : "form-control"}
                                value={this.state.notes || ''} />
                            <div className="invalid-feedback">
                                {this.state.formErrors.notes}
                            </div>
                        </div>
                    </div>

                    <div className="option-component-container my-5">

                        {this.state.opzioni.map((item, index)=> {

                                //console.log('preventivo (parent)',this.state.convalidaPreventivo.errors)



                                return ( item.isFixed ? <div key={index}></div> :
                                    <OpzionePreventivo
                                        key={index}
                                        selectedElements={this.state.opzioniArr}
                                        parentIsMulti={true}
                                        isNewRecord={(this.state.id ? false : true)}
                                        opzioniModificate={this.state.opzioniModificate}
                                        onChange={this.handleOpzioneChange.bind(this)}
                                        handleConfermaConflitto={this.handleConfermaConflitto.bind(this)}
                                        handleAnnullaConfermaConflitto={this.handleAnnullaConfermaConflitto.bind(this)}
                                        data-option-id={item._id}
                                        element={item}
                                        convalidaPreventivo={this.state.convalidaPreventivo}
                                        vincoliOpzioni={this.state.vincoliOpzioni}
                                        currentOptionId={this.state.currentOptionId}
                                        configurationErrors={this.state.convalidaPreventivo.errors}
                                        useAccordion={item.useAccordion}
                                        labelText={treeOpzioni.getTextInLangArr(item.nameArr, i18n.language)}
                                        language={i18n.language}
                                    />
                                )

                        })}
                    </div>

                    {this.state.customOptions.map((item, index) => {
                        return(
                            <CustomOption
                                key={index}
                                id={item.id}
                                text={item.text}
                                price={item.price}
                                onChange={this.handleCustomOptionChange.bind(this)}
                                onClick={this.deleteCustomOption}/>
                        )
                    })}

                    <Button onClick={this.addCustomOption} variant="outline-primary">{t('aggiungi_opzione')}</Button>

                </div>
                <hr />

                <div className="container mt-2 mb-3">
                    <div className="row">
                        <div className="col-12 col-md-9"><label>{t('prezzoTotale')}</label></div>
                        <div className="col-12 col-md-3 text-right"><div className="lead"><strong><FormatCurrency value={this.state.price}/></strong></div></div>
                    </div>
                </div>


                <hr />

                <div className="container mt-2 mb-3">
                    <div className="row">
                        <div className="col-12"><h4>{t('condizioniCommerciali')}</h4></div>
                    </div>

                    <div className="form-row my-1">
                        <div className="col-12 col-md-9"><label>{t('discount')}</label></div>
                        <div className="col-12 col-md-1 text-right negative-number">- €</div>
                        <div className="col-12 col-md-2">
                            <NumberFormat
                                placeholder= {t('discount')}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    this.setState({ discount: value });
                                }}
                                className="form-control text-right negative-number"
                                name="discount"
                                value={this.state.discount || ''}
                                displayType={'input'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                allowedDecimalSeparators={','}
                                allowNegative={false}
                                id="discount"/>
                            </div>
                    </div>


                    <div className="form-row my-1">
                        <div className="col-12 col-md-9"><label>{t('freightCharges')}</label></div>
                        <div className="col-12 col-md-1 text-right">€</div>
                        <div className="col-12 col-md-2">
                            <NumberFormat
                                placeholder= {t('freightCharges')}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    this.setState({ freightCharges: value });
                                }}
                                className="form-control text-right"
                                name="freightCharges"
                                value={this.state.freightCharges || ''}
                                displayType={'input'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                allowedDecimalSeparators={','}
                                allowNegative={false}
                                id="freightCharges"/>
                        </div>
                    </div>

                    <div className="form-row my-1">
                        <div className="col-12 col-md-9"><label>{t('tradeInValue')}</label></div>
                        <div className="col-12 col-md-1 text-right negative-number">- €</div>
                        <div className="col-12 col-md-2">
                            <NumberFormat
                                placeholder= {t('tradeInValue')}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    this.setState({ tradeInValue: value });
                                }}
                                className="form-control text-right negative-number"
                                name="tradeInValue"
                                value={this.state.tradeInValue || ''}
                                displayType={'input'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                allowedDecimalSeparators={','}
                                allowNegative={false}
                                id="tradeInValue"/>
                        </div>
                    </div>

                    <div className="form-row my-1">
                        <div className="col-12 col-md-6"><label>{t('tradeInDescription')}</label></div>
                        <div className="col-12 col-md-6">
                            <input
                                placeholder= {t('tradeInDescription')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                name="tradeInDescription"
                                type="text"
                                value={this.state.tradeInDescription || ''}
                                id="tradeInDescription"/>
                        </div>
                    </div>


                </div>

                <hr />

                <div className="container mt-2 mb-3">
                    <div className="row">
                        <div className="col-12"><h4>{t('condizioniVendita')}</h4></div>
                    </div>

                    <div className="form-row my-1">
                        <div className="col-12 col-md-6"><label>{t('validity')}</label></div>
                        <div className="col-12 col-md-6">
                            <input
                                placeholder= {t('validity')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                name="validity"
                                type="text"
                                value={this.state.validity || ''}
                                id="validity"/>
                        </div>
                    </div>


                    <div className="form-row my-1">
                        <div className="col-12 col-md-6"><label>{t('delivery')}</label></div>
                        <div className="col-12 col-md-6">
                            <input
                                placeholder= {t('delivery')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                name="delivery"
                                type="text"
                                value={this.state.delivery || ''}
                                id="delivery"/>
                        </div>
                    </div>

                    <div className="form-row my-1">
                        <div className="col-12 col-md-6"><label>{t('paymentTerms')}</label></div>
                        <div className="col-12 col-md-6">
                            <input
                                placeholder= {t('paymentTerms')}
                                onChange={this.handleInputChange}
                                className="form-control"
                                name="paymentTerms"
                                type="text"
                                value={this.state.paymentTerms || ''}
                                id="paymentTerms"/>
                        </div>
                    </div>


                </div>


                <div className="container mt-2 mb-3">

                    <div className="panel panel-default">
                        <FormErrors formErrors={this.state.formErrors} />
                    </div>

                    <div className="panel panel-default formErrors">
                    {(this.state.requiredErrors ) && this.state.requiredErrors.map((item, index)=>{
                        return(
                            <p className='bg-warning p-2' key={index}>{treeOpzioni.getTextInLangArr(item.whoOption.nameArr, i18n.language)} {t(item.type)}</p>
                        )
                    })}
                    </div>

                    <Button onClick={this.handleUpdate}>{t('salva')}</Button>
                    <CommonComponents.CancelButton href={'/preventivi/list/'}>{t('annulla')}</CommonComponents.CancelButton>

                </div>


                <div className="border bg-light d-lg-none">
                    <div className="container py-1 ">
                        <div className="row">
                            <div className="col-12 col-lg-10 text-left ">
                                <ul className="list-unstyled">
                                    {this.state.opzioniFixed.map((item, index    )=> {
                                        return(
                                            <li key={index}><small>{treeOpzioni.getTextInLangArr(item.nameArr, i18n.language)}</small></li>
                                        )
                                    })}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fixed-bottom border bg-light">
                    <div className="container pt-2 pb-1 ">
                        <div className="row">
                            <div className="col-12 col-lg-10 text-left d-none d-lg-block">
                                <ul className="list-unstyled">
                                    {this.state.opzioniFixed.map((item, index    )=> {
                                        return(
                                            <li key={index}><small>{treeOpzioni.getTextInLangArr(item.nameArr, i18n.language)}</small></li>
                                        )
                                    })}

                                </ul>
                            </div>

                            <div className="col-12 col-lg-2 text-right "><h3><FormatCurrency value={this.calcolaPrezzoNetto()}/></h3></div>
                        </div>
                    </div>
                </div>




            </>
        )
    }
}

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const auth = useAuth();
        //console.log('withMyHook authTokens', auth)
        return <Component {...props} authTokens={auth.authTokens} />;
    }
}

export default withTranslation('translations')(withMyHook(PreventivoUpdate))