import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_IT } from "./it/translations";
import { TRANSLATIONS_EN } from "./en/translations";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: TRANSLATIONS_EN
            },
            it: {
                translations: TRANSLATIONS_IT
            }
        },
        ns: ['translations'],
        defaultNS: 'translations',

        fallbackLng: 'it',

        keySeparator: false,

        interpolation: {
            formatSeparator: ','
        },

        react: {
            //wait: true,
            useSuspense: true,
        }
    });

//i18n.changeLanguage("it");

export default i18n;