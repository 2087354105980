
const required = (fieldName, fieldValue, errorLabel) => {

    //console.log(fieldName, '*'+fieldValue+'*')
    if(!errorLabel) errorLabel = 'is required'

    if (fieldValue===null || fieldValue===undefined || fieldValue.trim() === '') {
        return `${fieldName} ${errorLabel}`;
    }
    /*
    if (/[^a-zA-Z -]/.test(fieldValue)) {
        return 'Invalid characters';
    }
    if (fieldValue.trim().length < 3) {
        return `${fieldName} needs to be at least three characters`;
    }*/
    return null;
};



const email = (fieldName, fieldValue, errorLabel)=> {

    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(!fieldValue.match(mailformat)){
        return `${fieldName} ${errorLabel}`;
    }
   return null

};



/*

const nameValidation = (fieldName, fieldValue) => {
    if (fieldValue.trim() === '') {
        return `${fieldName} is required`;
    }
    if (/[^a-zA-Z -]/.test(fieldValue)) {
        return 'Invalid characters';
    }
    if (fieldValue.trim().length < 3) {
        return `${fieldName} needs to be at least three characters`;
    }
    return null;
};
 */



const fieldsValidator = {
    required,
    email,
}

export default fieldsValidator