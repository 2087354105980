import React, { Component } from 'react'
//import React, { Component, useState } from 'react'
//import Collapse from 'react-bootstrap/Collapse'
import FormatCurrency from '../utilities/FormatCurrency'
import treeOpzioni from "../utilities/treeOpzioni";
//import {BsFillInfoSquareFill} from 'react-icons/bs'
import { withTranslation } from 'react-i18next'


class PriceLabel extends Component {
    render() {
        return <FormatCurrency value={this.props.value} />
    }
}
/*
class OpzioneHelpText extends Component {
    render() {
        return <small className={this.props.className} >{this.props.text}</small>
        //return <></>
    }
}
*/
class FormCheckInput extends Component {
    render() {

        let className = (this.props.hasError === true ? 'is-invalid' : '') + ' form-check-input'

        return <input
            checked={this.props.isSelected}
            onChange={this.props.onChange}
            className={className}
            name={this.props.name}
            type={this.props.inputType}
            value={this.props.value}
            id={this.props.id}
        />
    }
}
/*
function InfoCollapse(props) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button

                onClick={() => setOpen(!open)}
                aria-controls={"collapse-text-" + props.id}
                aria-expanded={open}
                size="sm"
                variant="secondary"
                className="btn btn-link"
            >
                <BsFillInfoSquareFill/>
            </button>
            <Collapse in={open}>
                <div id={"collapse-text-" + props.id}>

                    { props.messages.map((item, index)=> {

                        let optionName = treeOpzioni.getTextInLangArr(item.whoOption.nameArr, props.language)
                        let className = 'form-text ' + (item.isError ? 'text-danger' : 'text-muted')

                        return(<OpzioneHelpText
                                className={className}
                                text={props.t(item.type) + ' ' + optionName}
                                key={index}
                            />

                        )
                    })}

                </div>
            </Collapse>
        </>
    );
}
*/


class FieldOpzione extends Component {
    constructor(props) {
        super(props)
        this.state = {
            element: this.props.element
        }
    }

    hasRequiredError = function(){
        if(this.props.convalidaPreventivo && this.props.convalidaPreventivo.requiredErrors){
            //console.log('convalidaPreventivo requiredErrors', JSON.stringify(this.props.convalidaPreventivo.requiredErrors))
            // who
            return this.props.convalidaPreventivo.requiredErrors.find(element=>element.who === this.props.element._id) ? true : false
        }
    }

    /**
     * cerca l'opzione nei campi what dei vincoliOpzioni (filtra array)
     * controlla se l'opzione rispetta i vincoli
     * @returns hasErrors : boolean
     *          messages[{
     *              type: tipo vincolo,
     *              whoOption: opzione che richiede o esclude,
     *              isError : boolean se l'opzione rispetta il vincolo in base a selectedElements
     *          }]
     */
    controllaVincoli (){

        let messages = [],
            hasErrors = false

        let findMeInVincoli = this.props.vincoliOpzioni.filter(myOpzione => myOpzione.what === (this.props.element._id));


        findMeInVincoli.forEach(vincolo => {
            //console.log('findMeArr', vincolo)
            let message = {
                type: vincolo.type,
                whoOption: vincolo.whoOption
            }

            // controllo se opzione rispetta i vincoli
            if(this.props.selectedElements){
                //console.log('controllaVincoli selectedElements',this.props.selectedElements)
                let isWhoSelected = (this.props.selectedElements.findIndex(myOpzione => myOpzione.id === vincolo.who || myOpzione.text === vincolo.who) > -1)
                let isMeSelected = (this.props.selectedElements.findIndex(myOpzione => myOpzione.id === vincolo.what || myOpzione.text === vincolo.what) > -1)
                //console.log('isWhoSelected ' + vincolo.who,isWhoSelected)
                //console.log('isMeSelected ' + vincolo.what,isMeSelected)

                let isError = false;
                if(isWhoSelected){
                    if(vincolo.type === 'requires' && !isMeSelected){
                        isError = true
                        hasErrors = true
                    }

                    if(vincolo.type === 'excludes' && isMeSelected){
                        isError = true
                        hasErrors = true
                    }
                }

                message.isError = isError

            }
            messages.push(message)


        })

        //console.log('controllaVincoli messages', messages)
        return {hasErrors: hasErrors, messages: messages}
    }


    render() {
        let id = this.props.element._id,
            inputType = "checkbox",
            name = id + '',
            isSelected = false,
            findMeInSelected;

        //const { t } = this.props;
        //console.log('language', this.props.element)

        //this.hasRequiredError()
        let labelCssArr = ["d-flex", "form-check-label"],
            hasRequiredError = this.hasRequiredError()


        if(hasRequiredError === true) labelCssArr.push('text-danger')
        if(this.props.element.isSelectable) labelCssArr.push('not-selectable')

        let controllaVincoli = this.controllaVincoli()


        let priceLabel = <PriceLabel value={this.state.element.price} />
        let labelText = treeOpzioni.getTextInLangArr(this.props.element.nameArr, this.props.language)

        //let infoCollapse = controllaVincoli.messages.length > 0 ? <InfoCollapse t={t} id={id} language={this.props.language} messages={controllaVincoli.messages}/> : <></>


        let label = <><label className={labelCssArr.join(' ')} htmlFor={id}>
            <div className="flex-fill">{labelText}<br /><small className="d-none">{this.props.element._id}</small></div>
            <div className="flex-fill text-right">{this.state.element.price > 0 ? priceLabel : <></>}</div>
        </label></>


        let note = this.props.element.noteArr && this.props.element.noteArr.length > 0 ?
            <p className="mb-0 form-text text-muted"><small>{treeOpzioni.getTextInLangArr(this.props.element.noteArr, this.props.language)}</small></p> : ''

        //console.log('element', this.props.element);
        //console.log('parentElement',this.props.parentElement);
        //console.log(this.props.element.nameArr[0].text + ' ' + this.props.parentElement.nameArr[0].text)

        if(!this.props.element.isSelectable){
            if(this.props.showLabel===1){
                return <></>
            }else {
                return <div>{label} {this.state.element.price > 0 ? priceLabel : <></>}</div>
            }
        }


        if(this.props.parentElement && !this.props.parentElement.isMultiple) {
            name = this.props.parentElement._id
            inputType = "radio"
            // cerco  il campo per text in selectedElements
            findMeInSelected = this.props.selectedElements.find(myOpzione => myOpzione.text === (id));
        }else {
            // cerco il campo per id in selectedElements
            findMeInSelected = this.props.selectedElements.find(myOpzione => myOpzione.id === (id));
        }

        //if(findMeInSelected) isSelected = true;
        let inArray = this.props.opzioniModificate.find(element => element === name);

        if(findMeInSelected || (this.props.isNewRecord && this.props.element.isStandard && !inArray)) isSelected = true;


        let input = <FormCheckInput
            hasError={controllaVincoli.hasErrors || hasRequiredError}
            isSelected={isSelected}
            onChange={this.props.onChange}
            name={name}
            value={id}
            id={id}
            inputType={inputType} />


        return (<div className="form-check">{input}{label}{note}


        </div>)
    }
}

export default withTranslation('translations')(FieldOpzione)